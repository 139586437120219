import axios from "axios";
import getErrorMessageFromErrorCode from "../constants/ServerErrors";
import { ERROR_CODE, METHOD, HTTP_STATUS } from "./Constants";
import STRINGS from "../constants/Strings";

// constants
import { 
  URLS,  
} from "./ApiUrls";

// error lookup
import errorLookup from "./ErrorLookup";

import BaseApi from "./BaseApi";

export default class Axios extends BaseApi {
  
  constructor(showErrorModal) {
    super();
    this.showErrorModal = showErrorModal;

    let baseURL = "";
    this.axios = axios.create({
      baseURL,
    });

    this.responseInterceptor = this.axios.interceptors.response.use(
      (res) => {
        console.log("Axios responseInterceptor",res);
        if (res?.data?.error) {
          const errorObj = errorLookup(res.data.error);
          this.showErrorModal(errorObj.code, errorObj.message);
          return errorObj;
        }
        res.isError = false;
        return res;
      },

      (error) => {
        console.log("Axios ======",error);

        if (error?.response?.data) {
          let code = "";
          let errors = "";
          if(error.response.data && error.response.data.length !==0){
            code = error.response.data[0]?.code ? error.response.data[0]?.code  : 999;
            errors = error.response.data[0];
          }else{
            code = error.response.data.code;
            errors = error.response.data;
          }

          return getErrorMessageFromErrorCode(code, errors);
        } else if(!error) {
          return getErrorMessageFromErrorCode("500", {message:"Server error."});
        } else if(error.toString().startsWith("Error: Network Error")) {
          return getErrorMessageFromErrorCode("500", {message:"Network error."});
        } else {
          // this.showErrorModal(0, error.toString());
          return error.toString();
          //return getErrorMessageFromErrorCode("500", error);    
        }
        return Promise.reject(error);
      }
    );

  }

  callApi(method, url, requestBody, headers) {
    const env = process.env.REACT_APP_ENV;
    console.log("callApi",env,headers,requestBody);
    
    const token = sessionStorage.getItem(STRINGS.APP_TOKEN) ? sessionStorage.getItem(STRINGS.APP_TOKEN) : "";

    let config = {
      headers: {
        "content-type": "application/json",
        "Authorization":"Bearer " + token
      },
      method,
      url,
      // timeout: TIMEOUT_DURATION,
    };

    config[method !== METHOD.HTTP.GET ? "data" : "params"] = requestBody;
    
    if (headers && headers !=="") {
      config.headers = headers;
      if(headers["content-type"] === 'multipart/form-data'){
        const formData = new FormData();
        formData.append('username',requestBody.mail);
        formData.append('password',requestBody.password);
        formData.append('grant_type','password');
    
        config["data"] = formData;
      }

    }

  
    console.log("callApi",config, requestBody);

    return this.axios(config);
  }

  destructor = () => {
    this.axios.interceptors.response.eject(this.responseInterceptor);
  };
}
