import React, { useState, Component } from "react";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Material
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

//Components
import { RegisterModal } from "./register.modal";
import RegisterSection from "../common/RegisterSection/RegisterSection";

//Constants
import STRINGS from "../../constants/Strings";
//config
import { config } from "../../constants/Config";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegistration: false,
      user: {},
    };
  }

  componentDidMount() {
    this.isLoggedIn();
  }

  isLoggedIn = () => {
    const { isLoggedIn } = this.state;
    console.log("isLoggedIn", isLoggedIn);

    const token = sessionStorage.getItem(STRINGS.APP_TOKEN);
    const url = config.getBaseUri();
    if (token && token !== "" && !isLoggedIn) {
      window.location.href = url + "/dashboard";
    }
    console.log("isLoggedIn");
  };

  handleChangebtn = (event) => {
    const value = event.currentTarget.value;
    this.setState({ title: "test2" });
  };

  viewRegistration = (event) => {
    this.setState({
      showRegistration: true,
    });
  };

  handleCloseModal = (event) => {
    this.setState({
      showRegistration: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { showRegistration, user } = this.state;

    return (
      <section>
        {/***/}
        <RegisterSection />
      </section>
    );
  }
}

SignUpPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedSignUpPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(SignUpPage))
);

export { connectedSignUpPage as SignUpPage };
