import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#0000ff",
    },
    secondary: {
      main: '#4B286D',
      contrastText: '#4B286D',
    },
    third:{
      main: '#66CC00',
      contrastText: '#2B8000',
    },
    text: {
      disabled: "#71757B",
    },
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#2B8000',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {

      },
    },
    MuiPickersDay: {
      day: {
        color: '#4B286D',
      },
      daySelected: {
        backgroundColor: '#2B8000',
      },
      dayDisabled: {
        color: '#2B8000',
      },
      current: {
        color: '#2B8000',
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: '#2B8000',
      },
    },
  },
});

theme.props = {
  MuiButton: {
    disableElevation: false,
  },
  MuiInputLabel: {
    shrink: true,
  },
  MuiInput: {
    disableUnderline: false,
  },
  MuiTooltip: {
    arrow: true,
  },
};

theme.overrides = {
  MuiButton: {
    root: {
      textTransform: 'none',
    },
    containedPrimary: {
      '&:hover': {
        backgroundColor: "#FFFFFF",
        color: "#4B286D",
        borderColor: "#4B286D !important",
        borderWidth: '1px',
      },
    },
    containedSecondary: {
      fontWeight: 700,
    },
  },
  MuiTooltip: {
    tooltip: {
      backgroundColor: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
    arrow: {
      color: theme.palette.primary.main,
    },
  },
};

export default theme;