import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import ChangePasswordApi from "../../../communication/api/ChangePasswordApi";

import Moment from "moment";
import MomentTZ from "moment-timezone";
import { showApiError } from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("ChangePasswordSaga", action);
  const mail = action.data.mail;

  const changePasswordApi = new ChangePasswordApi(mail);
  try {
    const result = yield call(changePasswordApi.getData);
    console.log("ChangePasswordSaga", result);
    yield call(action.callback, result.data);
  } catch (error) {
    console.log("ChangePasswordSaga", error);
    yield put(showApiError(error.toString()));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.CHANGE_USER_PASSWORD, handleSaga);
}

export default sagaWatcher;
