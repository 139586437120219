import * as actionTypes from '../../../redux/actions/actionTypes';

export const getWorkoutByUserMail = (params,callback = () =>{}) =>({
    type: actionTypes.WORKOUT_BY_USER_MAIL,
    data:{
        mail: params.mail
    },
    callback
});

export const createWorkout = (params,callback = () =>{}) =>({
    type: actionTypes.WORKOUT_CREATE,
    data:{
        workout: params.workout
    },
    callback
});

export const startWorkout = (params,callback = () =>{}) =>({
    type: actionTypes.WORKOUT_START,
    data:{
        workout: params.workout
    },
    callback
});

export const updateWorkout = (params,callback = () =>{}) =>({
    type: actionTypes.WORKOUT_UPDATE,
    data:{
        workout: params.workout
    },
    callback
});

export const getLeaders = (params,callback = () =>{}) =>({
    type: actionTypes.GET_LEADERS,
    data:{
        top: params.top
    },
    callback
});
