import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// styles
import './terms.css';
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";

const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },

});


class TermsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }


    render() {

        return (
            <Grid container spacing={50}>
                <Grid item xs={12} className={"terms"}>
                    <Typography className={"terms-header"}>Terms and Conditions of Use</Typography>
                    <span className={"terms-text"}>
                    PLEASE READ THESE TERMS CAREFULLY.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    DO NOT ACCEPT THIS CONTRACT UNLESS YOU AGREE TO THESE TERMS.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    These Terms and Conditions of Use (the “Terms of Use”) apply to the Neuroplasticity Gym website located at https://neuroplasticitygym.com (the “Site”). The Site is the property of MVMTLAB Inc., a corporation registered in British Columbia, Canada. Please read these Terms of Use carefully. BY ACCESSING THIS SITE, YOU ACCEPT, WITHOUT LIMITATION OR QUALIFICATION, THESE TERMS OF USE, WHICH FORM A LEGALLY BINDING AGREEMENT. IF YOU DO NOT AGREE WITH THESE TERMS OF USE , PLEASE DO NOT USE THE SITE. The Site is intended to be used solely by individuals over the age of 18.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    OWNERSHIP OF CONTENT
                    </span>
                    <span className={"terms-text"}>
                    Unless otherwise indicated, this Site and its design, text, content, selection and arrangement of elements, organization, graphics, and the design, compilation, and other matters related to this Site (collectively, “Content”) are protected under applicable intellectual property and other laws, including, without limitation, those of Canada.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    All Content and intellectual property rights herein are the property of MVMTLAB Inc. or the material is included with the permission of the rights owner and is protected pursuant to applicable copyright and trademark laws. The posting of any Content on this Site does not constitute a waiver of any right in such Content. The reproduction, duplication, distribution (including by way of email or other electronic means), publication (including posting on another website) modification, copying or transmission of Content from this Site in any form or by any means is strictly prohibited without the express prior written consent of MVMTLAB Inc. Requests for permission to reproduce or distribute materials found on this Site can be made by contacting MVMTLAB Inc. in writing at 500-55 Water St, Vancouver BC V6B 1A1 or by email at hello@mvmtlab.com.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    The Content may only be used for personal, non-commercial purposes.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    MVMTLAB Inc. grants you a personal, limited, non-exclusive, non-transferable license to access, view and make personal and non-commercial use of this Site. You may not use this Site to gain unauthorized access to MVMTLAB Inc. networks or computer systems or to engage in any activity that disrupts or otherwise interferes or tampers with the Site (and associated servers or networks connected to the Site). You agree not to access the Site through any automated means (such as through the use of scripts or web crawlers, etc.).
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    TRADEMARKS
                    </span>
                    <span className={"terms-text"}>
                    The Neuroplasticity Gym logo and all page headers, custom graphics, button icons, trademarks, service marks and logos appearing on this Site, unless otherwise noted, are service marks, trademarks (whether registered or not) and/or trade dress of MVMTLAB Inc (the “Marks”). All other trademarks, names, logos, service marks and/or trade dress mentioned, displayed, cited or otherwise indicated on the Site are the property of their respective owners. You are not authorized to display or use the Marks in any manner without the express prior written consent of MVMTLAB Inc. You are not authorized to display or use trademarks, names, logos, service marks and/or trade dress of other owners without their express prior written consent. The use or misuse of the Marks or other trademarks, names, logos, service marks and/or trade dress or any other materials contained herein, except as permitted herein, is expressly prohibited.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    PRIVACY POLICY
                    </span>
                    <span className={"terms-text"}>
                    By agreeing to these Terms of Use, you agree to the terms of our Privacy Policy, which is located at https://neuroplasticitygym/privacy/ and is incorporated herein by reference. Before using this Site, please read through the Terms of Use and Privacy Policy carefully. All personal information provided to us as a result of your use of this Site will be handled in accordance with our Privacy Policy.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    ADDITIONAL TERMS, CONDITIONS AND AGREEMENTS
                    </span>
                    <span className={"terms-text"}>
                    By agreeing to these Terms of Use, you agree to the “Terms of Use”, which is located at https://neuroplasticitygym/terms-of-use/ and is incorporated herein by reference, including the Medical Disclaimer, Typical Results, and Earnings Disclaimer. In addition, we may require you to follow additional rules, guidelines or other terms and conditions in order to use various features of our Site, to participate in certain promotions or activities available through our Site, or for other reasons. In such cases, you may be asked to expressly consent to these additional terms and conditions, for example, by checking a box or clicking on a button marked “I agree.” This type of agreement is known as a “click-through” agreement. For example, you will be required to enter into a separate agreement if you use this Site (or any other site associated with MVMTLAB Inc.) to purchase one of our programs. If any of the terms or conditions of a click-through agreement are different than the terms of these Terms of Use, then the terms of the click-through agreement will supplement or amend these Terms of Use, but only with respect to the matters governed by such agreement. In addition, certain areas of our Site may be operated by third party service providers. Such areas may be governed by certain additional terms, conditions, or policies of those third party providers.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    SITE SUBMISSIONS
                    </span>
                    <span className={"terms-text"}>
                    You may choose to submit information to MVMTLAB Inc. through our Site for various reasons, such as to login, provide a testimonial, or to make a purchase. By submitting information to MVMTLAB Inc., you are authorizing us to utilize this information for the purpose for which it was submitted and for such other purposes as set out in our Privacy Policy. Nothing in these Terms of Use or the Site shall constitute an offer or promise of employment or licensing relationship. We may or may not review all or any information submitted to us.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    Except for personally identifiable information such as names, addresses, and financial information, unsolicited information submitted to this Site is assigned to MVMTLAB Inc. free of charge, together with all worldwide rights, title and interest in all copyrights and other intellectual property rights in such submission and MVMTLAB Inc. and its affiliates and related entities may use, sublicense, adapt, distribute, publicly perform, publish, display or delete such submission at its sole discretion. By sending any unsolicited submission to MVMTLAB Inc., you waive the right to make any claim against MVMTLAB Inc., its parents or affiliates related to use of the submission.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    ADVERTISING AND LINKS
                    </span>
                    <span className={"terms-text"}>
                    With our prior written consent, you may create hyperlinks directly to the home page of this Site, provided that: (i) the hyperlink accurately describes the content as it appears on the Site; (ii) each page within our Site is displayed in full, without any accompanying frame, border, margin, design, branding, trademark, advertising or promotional materials not originally displayed on the applicable page within our Site; and (iii) you do not represent in any way, expressly or by implication, that you have received the endorsement, sponsorship or support of this Site, MVMTLAB Inc., or its agents. You may not copy any portion of the Site or Content to a server, except as part of an incidental caching of pages. If you wish to link to or obtain a license with regard to the Site or the Content other than as described herein, you must contact MVMTLAB Inc. by email at hello@mvmtlab.com or 500-55 Water St, Vancouver BC V6B1A1 before doing so. If MVMTLAB Inc. has provided links or pointers to other websites, then no inference or assumption should be made and no representation should be implied that MVMTLAB Inc. is connected with, operates or controls these websites. MVMTLAB Inc. is not responsible for the content or practices of third party websites that may be linked to this Site.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    TERMINATION
                    </span>
                    <span className={"terms-text"}>
                    MVMTLAB Inc. reserves the right to immediately terminate your use of, or access to, this Site at any time for any reason in its sole discretion. MVMTLAB Inc. further reserves the right to modify or discontinue this Site or any portion thereof at any time without notice.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    COPYRIGHT INFRINGEMENT
                    </span>
                    <span className={"terms-text"}>
                    If you believe that any material contained in this Site infringes your copyright, you should notify MVMTLAB Inc. of your copyright infringement claim in accordance with the following procedure. MVMTLAB Inc. will process notices of alleged infringement which it receives and will take appropriate action.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    NO WARRANTIES
                    </span>
                    <span className={"terms-text"}>
                    WE MAKE NO REPRESENTATIONS OR WARRANTIES WITH RESPECT TO THIS SITE OR ITS CONTENTS OR ANY GOODS OR SERVICES SOLD THROUGH THIS SITE, OR ANY SITE WITH WHICH IT IS LINKED, WHICH ARE EACH PROVIDED FOR USE “AS IS” AND ON AN “AS AVAILABLE” BASIS. WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, QUALITY AND FITNESS FOR A PARTICULAR PURPOSE, WITH RESPECT TO THIS SITE, ANY GOODS OR SERVICES SOLD THROUGH THIS SITE, AND ANY SITE WITH WHICH THIS SITE MAY BE LINKED. WE DO NOT WARRANT THAT THIS SITE, ITS SERVERS OR EMAIL SENT FROM US WILL BE FREE OF ANY HARMFUL COMPONENTS (INCLUDING VIRUSES). WE ALSO MAKE NO REPRESENTATIONS OR WARRANTIES AS TO WHETHER THE INFORMATION ACCESSIBLE VIA THIS SITE, OR ANY SITE WITH WHICH IT IS LINKED, IS ACCURATE, COMPLETE, OR CURRENT. WE DO NOT PROVIDE ANY REPRESENTATIONS OR WARRANTIES AGAINST THE POSSIBILITY OF DELETION, MISDELIVERY OR FAILURE TO STORE COMMUNICATIONS, PERSONALIZED SETTINGS, OR OTHER DATA. YOU ACCEPT THAT OUR OFFICERS, DIRECTORS, EMPLOYEES, LICENSEES, AND SUPPLIERS SHALL HAVE THE BENEFIT OF THIS CLAUSE.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    EXCLUSION OF LIABILITY
                    </span>
                    <span className={"terms-text"}>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAWS, WE, ON BEHALF OF OUR OFFICERS, DIRECTORS, EMPLOYEES, LICENSEES, AND SUPPLIERS, EXCLUDE AND DISCLAIM LIABILITY FOR ANY LOSSES AND EXPENSES OF WHATEVER NATURE AND HOWSOEVER ARISING INCLUDING, WITHOUT LIMITATION, ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES, LOSS OF USE, LOSS OF DATA, LOSS CAUSED BY A VIRUS, LOSS OF INCOME OR PROFIT, LOSS OF OR DAMAGE TO PROPERTY, CLAIMS OF THIRD PARTIES, OR OTHER LOSSES OF ANY KIND OR CHARACTER, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES, ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE SITE, THE PURCHASE OF GOODS OR SERVICES SOLD THROUGH THIS SITE, OR ANY WEBSITE WITH WHICH THIS SITE MAY BE LINKED. THIS LIMITATION OF LIABILITY APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, EQUITY, NEGLIGENCE, TORT, STRICT LIABILITY OR ANY OTHER BASIS.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    These Terms of Use give you specific legal rights and you may also have other rights which vary by jurisdiction. Some jurisdictions do not allow the exclusion of implied warranties or certain kinds of limitations or exclusions of liability, so the limitations and exclusions set out in these Terms of Use may not apply to you. Other jurisdictions allow limitations and exclusions subject to certain conditions. In such a case the limitations and exclusions set out in these Terms of Use shall apply to the fullest extent permitted by the laws of such applicable jurisdictions. Your statutory rights as a consumer, if any, are not affected by these provisions, and we do not seek to exclude or limit liability for fraudulent misrepresentation.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    INDEMNITY
                    </span>
                    <span className={"terms-text"}>
                    You agree to defend, indemnify and hold harmless MVMTLAB Inc., its directors, officers, managers, members, employees and agents, from and against all the liabilities, claims, damages and expenses (including reasonable attorney’s fees and costs) arising out of or related to your use of this Site, any content you submit, post to, or transmit through this Site or your breach or alleged breach of these Terms of Use.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    LEGAL COMPLIANCE
                    </span>
                    <span className={"terms-text"}>
                    You shall comply with all applicable laws, statutes, ordinances and regulations regarding your use of the Site. If you provide us any information through the Site, you agree to provide true, accurate, current and complete information about yourself. You agree not to attempt to upload or transmit through the Site any computer viruses, trojan horses, worms or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer or system. Any unauthorized modification, tampering or change of any information, or any interference with the availability of or access to this Site is strictly prohibited. If you become aware of misuse of this Site by any person, then please contact the Site Administrator at hello@mvmtlab.com with your concerns. MVMTLAB Inc. reserves all rights and remedies available to it.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    GENERAL PROVISIONS
                    </span>
                    <span className={"terms-text"}>
                    These Terms of Use operate to the fullest extent permissible by law. If any provision of these Terms of Use is unlawful, void or unenforceable, that provision is deemed severable from these Terms of Use, will be substituted with a valid and enforceable provision reflecting the intent of the invalid provision, and does not affect the validity and enforceability of any remaining provisions. No provision of these Terms of Use shall be waived except with prior written consent of MVMTLAB Inc.  The failure to exercise, or delay in exercising, any right or remedy under these Terms of Use will not operate as a waiver. These Terms of Use may be assigned by MVMTLAB Inc in our sole discretion. Headings are for reference purposes only and in no way define, limit, construe or describe the scope or extent of such section. Failure to act with respect to a breach of this agreement does not waive our right to act with respect to subsequent or similar breaches. These Terms of Use set forth the entire understanding and agreement between us with respect to the subject matter hereof.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    MODIFICATION
                    </span>
                    <span className={"terms-text"}>
                    MVMTLAB Inc reserves the right to modify or amend these Terms of Use without notice at any time. The latest Terms of Use will be posted on the Site, and you should always review these Terms of Use prior to using the Site to ensure that you have a current understanding of the Terms of Use under which you are permitted to access this Site. Use by you of the Site following any modifications or amendments to these Terms of Use shall signify your acceptance of such modifications or amendments.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    NOTICES
                    </span>
                    <span className={"terms-text"}>
                    Except as explicitly stated otherwise, any notices shall be given by postal mail to MVMTLAB Inc., #500-55 Water St, Vancouver BC V6B1A1 (in the case of notice to neuroplasticity gym) or to the email address you provide to MVMTLAB Inc. (in the case of notice to you). Notice shall be deemed given 24 hours after email is sent, unless the sending party is notified that the email address is invalid. Alternatively, we may give you notice by certified mail, postage prepaid and return receipt requested, to the address you have provided to MVMTLAB Inc. In such case, notice shall be deemed given 3 days after the date of mailing.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    CONTACTING MVMTLAB Inc.
                    </span>
                    <span className={"terms-text"}>
                    Any questions, comments, or issue with this Site should be addressed to the Site Administrator at hello@mvmtlab.com
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    GOVERNING LAW, VENUE AND DISPUTE RESOLUTION
                    </span>
                    <span className={"terms-text"}>
                    This Agreement is governed by, and construed in accordance with, the laws of the province of British Columbia, without giving effect to any principles of conflicts of law. You agree to submit to the exclusive jurisdiction of the courts of the province of British Columbia.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    DISPUTE RESOLUTION
                    </span>
                    <span className={"terms-text"}>
                    The parties shall first attempt, promptly and in good faith, to resolve any such dispute informally between them. If the parties are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days), then either party shall submit such controversy or dispute to individual mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law and in accordance with these Terms of Use.
                    </span>
                    <br/>
                    <span className={"terms-text"}>
                    ACCEPTANCE
                    </span>
                    <span className={"terms-text"}>
                    Registering an account on the site means you understand that this is a binding contract, you understand the terms and conditions explained here, and you agree to comply with all the terms and conditions.
                    </span>
                </Grid>
            </Grid>
        );
    }
}

TermsPage.propTypes = {};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const connectedTermsPage = withRouter(connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false
})(withStyles(styles)(TermsPage)));

export {connectedTermsPage as TermsPage};
