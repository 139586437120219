import React, { useState, Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Material
import Button from "@material-ui/core/Button";

//Constants
import STRINGS from "../../../constants/Strings";
import { ForgotPasswordModal } from "../../forgot-password/forgot-password.modal";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class ForgotPasswordButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showForgotPasword: false,
    };
  }

  viewForgotPassowrd = (event) => {
    this.setState({
      showForgotPasword: true,
    });
  };

  handleCloseModal = (event) => {
    this.setState({
      showForgotPasword: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { showForgotPasword } = this.state;

    return (
      <>
        {/***/}
        <Button
          onClick={(event) => this.viewForgotPassowrd(event)}
          variant="contained"
          color="primary"
        >
          Forgot Password
        </Button>
        <ForgotPasswordModal
          showModal={showForgotPasword}
          handleCloseModal={this.handleCloseModal}
        />
      </>
    );
  }
}

ForgotPasswordButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, null, null, {
  pure: false,
})(withStyles(styles)(ForgotPasswordButton));

// export default { connectedForgotPasswordButton as ForgotPasswordButton };
