const STRINGS = {
  APP_TOKEN: "APP_TOKEN",
  APP_USERNAME: "APP_USERNAME",
  APP_TITLE: "Neuroplasticity Gym",
  LOGIN: {
    ERROR: "APP_TOKEN",
  },
  CONTACT: {
    SENT: "Thank you for your inquiry.",
  },
  NAVBAR: {
    HEADER_TITLE: "TCC Incident Support Guides",
    FOOTER_TITLE: "",
  },
  OBJECTIVES: [
    {
      id: 1,
      HEADING: "Clinically proven approach",
      SUBHEAD: "",
      BODY: "Used clinically for a decade, our approach was born in elite sports performance and is now ready to be used by you. Your body is all you need, along with the principles of neuroplasticity; focus, motivation and our 30-day programs to beat chronic neuromuscular pain.",
      EXPANDED: false,
    },
    {
      id: 2,
      HEADING: "Accountability to ensure success",
      SUBHEAD: "",
      BODY: "Check in daily to certify your assignments and watch your neuroplasticity score grow. ",
      EXPANDED: false,
    },
    {
      id: 3,
      HEADING: "Neurohack your body back",
      SUBHEAD: "",
      BODY: "Forgotten injuries from the past lead to neuroprotective patterns, limiting your strength, balance and ease of movement. Follow our daily neuromuscular focused programming to feed your brain the necessary input to facilitate change. Our functional meditation approach to movement performance will re-establish your true stability baseline.",
      EXPANDED: false,
    },
    {
      id: 4,
      HEADING: "These programs aren't easy",
      SUBHEAD: "",
      BODY: "The good thing is that the assignments aren't very long. Follow our daily video and text instruction and you will feel what makes these programs so beneficial. Open the windows to learning and witness your primary movement patterns awaken.",
      EXPANDED: false,
    },
    {
      id: 5,
      HEADING: "Learn what your body needs",
      SUBHEAD:
        "",
      BODY: "Apply a personalized, fundamental routine to use for the rest of your moving life",
      EXPANDED: false,
    },
  ],
  COMMON: {
    LEARN_MORE: "LEARN MORE",
  },
  HOMEPAGE: {
    BRAND_DETAILS:
      "Your total neuromuscular rebuild begins with the principles of neuroplasticity; focus, motivation and our 30-day programs to engage global stability patterns to beat chronic neuromuscular pain.",
    THE_FUTURE_OF_MOVING: "The Future of moving, is not moving.",
    TESTIMONIAL: {
      TITLE: "Testimonial",
      LIST: [
        {
          firstName: "Patrick",
          lastName: "",
          mediaUser: "thoughtfulmeathead",
          link: "https://instagram.com/thoughtfulmeathead",
          testimonial: `Nothing has come close to the deep, lasting changes that Dr. Jeff’s Ground Control program has been able to produce in the way I move and feel. 
          Ground Control is to physical pain what psychedelic medicine is to emotional pain - it addresses the root cause(s). 
          The program connects the dots between motor development, bio psychology, and breathwork and meditation in a way that is guaranteed to fix the most stubborn chronic aches and pains. I can’t say enough good things.`,
        },
        {
          firstName: "Alex ",
          lastName: "",
          mediaUser: "thenavaslab",
          link: "https://instagram.com/thenavaslab",
          testimonial: `Well it feels like my hips and thighs were replaced with cyborg upgrades which were then accidentally plugged into a high voltage line.`,
        },
        {
          firstName: "Mercedes",
          lastName: "",
          mediaUser: "mercedesnicoll",
          link: "https://instagram.com/mercedesnicoll",
          testimonial: `I wish I'd found Jeff earlier! Having a crash at the Sochi 2014 Games, doing all that I can to get back on snow, 
            I finally see myself getting back on snow (and) started to get my brain working with my body again!`,
        },
        {
          firstName: "Cameo",
          lastName: "",
          mediaUser: "cameo_wilson",
          link: "https://www.instagram.com/cameo_wilson/",
          testimonial: `Being a professional skateboarder, the sport has taken a huge toll on my body. 
            His Ground Control therapy has been extremely effective and he has me on the path for a full recovery. Thank you Dr.Jeff!!`,
        },
      ],
    },
    OBJECTIVE_IMAGES: [
      {
        id: 1,
        src:
          process.env.PUBLIC_URL +
          "/resources/600d0649a2f87f7ee9571023_Pose1.png",
        title: "pose 1",
      },
      {
        id: 2,
        src:
          process.env.PUBLIC_URL +
          "/resources/600d1e4c6b19a0d4ce3c5cbf_Pose2.png",
        title: "pose 2",
      },
      {
        id: 1,
        src:
          process.env.PUBLIC_URL +
          "/resources/600d247ac1eb6ec71a60e0ac_Pose3.png",
        title: "pose 3",
      },
    ],
    PROGRAM_LIST: [
      {
        IMG: process.env.PUBLIC_URL + "/resources/ground-control.svg",
        HEADER: "GROUND CONTROL",
        SUBHEADER:
          "Neurohack your body back. Relearn the infant development milestones.",
      },
      {
        IMG: process.env.PUBLIC_URL + "/resources/shoulder-codex.png",
        HEADER: "Shoulder Codex",
        SUBHEADER: "Functional meditation with your shoulders in mind.",
      },
      {
        IMG: process.env.PUBLIC_URL + "/resources/centrepoint.svg",
        HEADER: "Centrepoint",
        SUBHEADER: "Set yourself straight by going-on autopilot",
      },
      // {
      //   IMG: process.env.PUBLIC_URL + "/resources/aera.svg",
      //   HEADER: "Aera",
      //   SUBHEADER: "Learn how engaging into a cramp is beneficial for your motor control.",
      // },
      // {
      //   IMG: process.env.PUBLIC_URL + "/resources/infinity.svg",
      //   HEADER: "Infinity Mode",
      //   SUBHEADER: "Random assignments from previous programs.",
      // },
    ],
  },
  COURSE_PAGE: {
    PROGRAM_LIST: [
      {
        IMG: process.env.PUBLIC_URL + "/resources/ground-control.svg",
        HEADER: "Ground Control",
        PARAGRAPH:
          "Ground Control consists of holding early developmental milestone positions for time. These milestone positions are the building blocks of movement which we all learn in infancy. However once we learn to walk and run, we rarely go back to practice them. \n" +
          "</br></br>" +
          "When we incur injuries, our brain establishes protective patterns, which are essentially like turning on the emergency brakes of movement. Trying harder to pattern in new movements can actually reinforce these protective patterns, trapping us in a pain-loop of chronic dysfunction and pain. \n" +
          "</br></br>" +
          "Using functional meditation, we can reconnect to these early movement milestones creating easier, automatic movement and a better understanding of our individual movement signatures. \n",
      },
      {
        IMG: process.env.PUBLIC_URL + "/resources/shoulder-codex.png",
        HEADER: "Shoulder Codex",
        PARAGRAPH:
          "The Shoulder Codex is a course specifically designed for individuals with chronic shoulder pain or dysfunction. It leads the participant through technical shoulder and global stability positions with the goal of unloading the cervical spine and engaging the shelf, where the shoulder complex is postured onto the body. \n" +
          "</br></br>" +
          "The program is progressive and increases in difficulty as the participants stability endurance increases. Participants should expect shaking and aching as the time under tension will challenge the individual. As the positions become increasingly difficult to hold, arm positions are switched. There are 7 shoulder positions in this course.\n",
      },
      {
        IMG: process.env.PUBLIC_URL + "/resources/centrepoint.svg",
        HEADER: "Centrepoint",
        PARAGRAPH:
          "Set yourself straight by going-on autopilot. This course is designed to allow the sub-programs of balance come to the forefront. It is vestibular and cerebellar training, challenging your body's ability to stand over a minimized base of support.\n" +
          "</br></br>" +
          "It is on the opposite spectrum of challenge compared to Ground Control and the Shoulder Codex, however it is not easy in it's own right. Learn to access your body's self rightening reflexes and find a more solid, balanced connection to the ground. \n",
      },
      // {
      //   IMG: process.env.PUBLIC_URL + "/resources/aera.png",
      //   HEADER: "Aera",
      //   PARAGRAPH:
      //     "Approximated End Range Activation is a program that enters the strange world of muscle cramping - and it's not for everyone. Learn how engaging into a cramp is beneficial for your motor control. And it's a secret weapon for chronic pain.\n" +
      //     "</br></br>" +
      //     "This program further divides into 3-sub programs; framing, solidify and phasing. \n" +
      //     "</br></br>" +
      //     "To unlock this program, the participant must finish GC1/2, Shoulder Codex 1/2 and Centrepoint.\n",
      // },
      // {
      //   IMG: process.env.PUBLIC_URL + "/resources/infinity.png",
      //   HEADER: "Infinity Mode",
      //   PARAGRAPH:
      //     "Once all the programs have been completed, infinity mode will randomly provide an assignment from previous programs. ",
      // },
    ],
    TITLE: "Neuroplasticity Gym",
    PARAGRAPH: `Start with a 30-day program that suits your needs. You may begin with our Shoulder Codex program for shoulder and neck pain, 
    Ground Control for Spine and hip dysfunction or Centrepoint for balance relates issues. 
    Or join in for a year to attain global stability and neurohack your body back.`,
  },
  SIGNIN: {
    SIGN_IN_BREADCRUMB: "Sign In",
    VAULTSIGNINTITLE: "SIGN IN",
    CHANGE_PASSWORD: "Confirm change password",
    FORM: {
      USERNAMELABEL: "Email",
      EMAILPLACEHOLDER: "Enter email",
      PASSWORDLABEL: "Password",
      PASSWORDPLACEHOLDER: "Enter password",
      FORGOTPASSWORDLINK: "Forgot password",
      REMEBERUSERCHECK: "Remember me",
      SUBMITBUTTON: "Sign In",
    },
    API_KEYS: {
      SIGNIN: "signIn",
    },
  },
  REGISTRATION: {
    CONFIRM_CREATE: "Confirm Registration",
    REGISTER_BREADCRUMB: "Register",
    TITLE: "REGISTER",
    MONTHLY_DETAILS: "Commit to a single 30 day program.",
    YEARLY_DETAILS: "Dive deep with a yearly membership",
    FORM: {
      EMAILLABEL: "Email",
      EMAILPLACEHOLDER: "Enter email",
      MOBILENOLABEL: "Mobile no.",
      MOBILENOPLACEHOLDER: "Enter mobile no.",
      USERNAMELABEL: "Username",
      USERNAMEPLACEHOLDER: "Enter Username",
      TERMSCHECK: "Remember me",
      REGISTRATIONBTN: "Register",
    },
    SECURESESSIONTEXT: "Session secured with SSL and 256-bit encryption.",
    API_KEYS: {
      EXISTING_USER: "existingUser",
      REGISTER: "Register",
    },
  },

  GROUND_CONTROL_ID: "Ground Control",
  SHOULDER_CODEX_ID: "Shoulder Codex",
  FULL_CONTROL_ID: "Full Control",
  BALANCE_DAY: "Centrepoint",
  AREA: "AERA",
  INFINITY_MODE: "Infinity Mode",
};

export default STRINGS;
