import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetWorkoutCategoryByUserApi extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};
  url = BE_NEURO_URL+"workoutCategory/mail/";

  constructor(mail) {
    super();
    this.url = this.url + mail;
    console.log("GetWorkoutCategoryByUserApi-",this.url,mail);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
