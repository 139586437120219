import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

//Material
import {Grid, Box} from '@material-ui/core';
import { Collapse } from '@mui/material'; 
import { Paper, Button } from '@mui/material';
import Carousel from 'react-material-ui-carousel'
// import Carousel from 'react-bootstrap/Carousel'
import Typography from '@material-ui/core/Typography';

//Constants
import STRINGS from "../../constants/Strings";

//Styles
import './styles/clinically.proven.css';
import { CarouselItem } from 'react-bootstrap';

//Components


const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },

}); 
  

class ClinicallyProven extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "Test", 
            objectives: STRINGS.OBJECTIVES,
            objectivesImages: STRINGS.HOMEPAGE.OBJECTIVE_IMAGES,
            expanded: false,
        }

        this.handleExpand.bind(this);
    }

    handleChange = (panel) => (event, newExpanded) => {
        this.setState({
            expanded: newExpanded ? panel : false,
        })
        // setExpanded(newExpanded ? panel : false);
    };

    handleExpand = (data) => {
        const {objectives} = this.state;
        data.EXPANDED = !data.EXPANDED;
        this.setState({
            objectives: [...objectives],
        })
    }
 
    render() {
        const { objectives, objectivesImages} = this.state;
        const {classes} = this.props;
        // const classesClinically = 
        return (
            <>  
                <div className={"width-div-grid"}>
                    <Grid className={"section-container"} container direction="row-reverse" alignItems="center" justifyContent='center' >
                        <Grid item xs={12} md={6} sm={6}>  
                        
                                <Carousel 
                                    indicators={false}
                                    fade={true}
                                    swipe={true}
                                >         
                                    {
                                        objectivesImages.map( ({id, src, title}) =>  
                                            
                                            <Paper key={id} className={"imgObjective  "}>
                                                <img src={ src }  title={title} alt={title} class="image-sticky"/>
                                            </Paper>
                                        )
                                    }
                                </Carousel> 
                                
                        </Grid>
                        <Grid item xs={12} md={6}  sm={6} className={""}>  
                                { objectives.map( (data, idx ) =>
                                    <> 
                                        <Grid item xs={12} spacing={6}>
                                            <div  class="div-block-7" onClick={(e) => this.handleExpand(data)}>
                                                
                                                    <h2 class="heading-4"><span class="text-span-6">+</span> { data.HEADING }</h2>
                                                    <p class="paragraph-subhead">{ data.SUBHEAD }</p>
                                                    
                                                    <Collapse in={data.EXPANDED} timeout={1000} unmountOnExit>
                                                        <p 
                                                        class="paragraph">
                                                            {
                                                                data.BODY
                                                            }
                                                        </p>
                                                    </Collapse>
                                            </div>
                                        </Grid>
                                    </>  
                                )}  
                        </Grid> 
                    </Grid>   
                </div>
                 
            </>
        );
    }
}

ClinicallyProven.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}

const connectedClinicallyProven = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(ClinicallyProven)));

export {connectedClinicallyProven as ClinicallyProven};

