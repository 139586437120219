import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Material
import { Grid, Box } from "@material-ui/core";
import { Collapse } from "@mui/material";
import { Paper, Button } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import Typography from "@material-ui/core/Typography";

//Constants
import STRINGS from "../../constants/Strings";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class Programs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "PROGRAMS",
    };
  }

  render() {
    const { classes } = this.props;
    const list = STRINGS.HOMEPAGE.PROGRAM_LIST;
    return (
      <Grid container className={"section-container"}>
        <Grid item xs={12}>
          <h1 className={"heading"}>Programs</h1>
        </Grid>

        <Grid container>
          {list.map((data, idx) => (
            <>
              <Grid xs={12} md={4}>
                <a
                  id="w-node-b3568707-a6f2-e7f7-47db-52e63d92c2c0-0e79e2ab"
                  data-w-id="b3568707-a6f2-e7f7-47db-52e63d92c2c0"
                  href={process.env.PUBLIC_URL + "/courses"}
                  class="program-block w-inline-block"
                >
                  <div class="div-block">
                    <img src={data.IMG} loading="lazy" alt="" class="image-2" />
                  </div>
                  <h2 class="heading-2 center">{data.HEADER}</h2>
                  <p class="paragraph center-block">{data.SUBHEADER}</p>
                  <div class="nonlink-button">
                    <h4 class="heading-3">{STRINGS.COMMON.LEARN_MORE}</h4>
                  </div>
                </a>
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    );
  }
}

Programs.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedPrograms = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Programs))
);

export { connectedPrograms as Programs };
