import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";
import {
  showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

import WorkoutCategoryUpdateApi from "../../../communication/api/WorkoutCategoryUpdateApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("WorkoutCategoryUpdateApi",action);
  const workout = action.data.workout;

  const workoutCategoryUpdateApi = new WorkoutCategoryUpdateApi(workout);
  try {

    const result = yield call(workoutCategoryUpdateApi.getData);
    console.log("WorkoutCategoryUpdateApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("WorkoutCategoryUpdateApi",error);
   // yield put(showApiError(error));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.WORKOUT_UPDATE, handleSaga);
}

export default sagaWatcher;
