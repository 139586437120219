import React, {Component} from "react";

import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

//Material
import Carousel from "react-material-ui-carousel";

//Components

//Action
import {createWorkout, getWorkoutByUserMail, startWorkout, updateWorkout,} from "./actions/WorkoutAction";

//Action
import {getUserByMail} from "../registration/actions/UserAction";

//Action
import {getMemberships} from "../profile/actions/ProfileAction";

//Constants
import STRINGS from "../../constants/Strings";

import "./styles/dashboard.css";
import {Workout} from "./workout";

const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },
});

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
            user: {},
            counter: {
                focus: 0,
                streak: 0,
                time: 0,
            },
            workout: [],
            timeRemaining: 0,
            dailyTimer: "00:00",
            hasWorkout: false,
            subscriptions: [],
        };
    }

    componentDidMount() {
        this.getUser();
        this.getWorkoutByUser();

        this.initializeCountdown();

        console.log("TEST");
    }

    initializeCountdown() {
        let timeRemain = 0;

        // get current Datetime
        const currentDateTime = new Date(); // This will get the system time of terminal
        let hours = 24 - currentDateTime.getHours(); // get hours
        let minutes = 59 - currentDateTime.getMinutes(); // get minutes
        let seconds = 59 - currentDateTime.getSeconds(); // get seconds

        timeRemain = this.milliseconds(hours, minutes, seconds); // get the timeremaining
        // hours = hours > 9 ? hours : "0" + hours;
        // minutes = minutes > 9 ? minutes : "0" + minutes;
        // seconds = seconds > 9 ? seconds : "0" + seconds;

        const countdownIntervalID = setInterval(() => {
            const {hour, minute, second} = this.msToTime(timeRemain);

            let dailyTimerString = `${hour}:${minute}:${second}`;
            this.setState({
                dailyTimer: dailyTimerString,
                timeRemaining: timeRemain,
            });
            // update the timer
            timeRemain -= 1000;
            // console.log(dailyTimerString);
            if (timeRemain < 0) {
                // call the callback
                this.complete();

                // clear the interval if expired
                clearInterval(countdownIntervalID);
            }
        }, 1000);
    }

    complete() {
        console.log("COMPLETE COUNTDOWN");
    }

    // convert total milliseconds to hours seconds minutes
    msToTime = (duration) => {
        var milliseconds = Math.floor((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        return {hour: hours, minute: minutes, second: seconds};
    };

    // get milliseconds
    milliseconds = (h, m, s) => (h * 60 * 60 + m * 60 + s) * 1000;

    getUser() {
        const {dogetUserByMail} = this.props;
        const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);

        dogetUserByMail({mail: mail}, (user) => {
            console.log("getUser", user);
            if (user) {
                this.getMemberships(mail);
                this.setState({user: user});
            }
        });
    }

    getMemberships(username) {
        const {doGetMemberships} = this.props;
        const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);
        const params = {
            username: username,
        };

        doGetMemberships(params, (results) => {
            console.log("getMemberships", results);
            if (results) {
                this.setState({subscriptions: results});
            }
        });
    }

    startProgram = (workoutId) => {
        console.log("startProgram",workoutId)
        const {doStartWorkout} = this.props;
        doStartWorkout({workout: {id: workoutId}}, (res) => {
            console.log("start result", res);
            this.getWorkoutByUser();
        })
    }

    getWorkoutByUser = (callback) => {
        console.log("getWorkoutByUser");
        const {dogetWorkoutByUserMail} = this.props;
        const params = {
            mail: sessionStorage.getItem(STRINGS.APP_USERNAME),
        };

        dogetWorkoutByUserMail(params, (res) => {
            console.log("getWorkoutByUser", res);
            if (res && res.length !== 0) {
                const workout = res;
                const user = workout[0].user;
                this.setState({
                    workout: workout,
                    hasWorkout: true,
                    user: user,
                }, () => {
                    if(callback) callback();
                });
            } else {
                this.setState({hasWorkout: false});
            }
        });
    }

    handleChangebtn = (event) => {
        const value = event.currentTarget.value;
        this.setState({title: "test2"});
    };

    setWorkout = (workout) => {
        this.setState({workout: workout});
    };

    render() {
        const {classes} = this.props;
        const {index, user, counter, workout, subscriptions, dailyTimer} =
            this.state;
        
        var items = [
            {
                name: STRINGS.GROUND_CONTROL_ID,
                workout: "",
                isSecond: false,
            },
            {
                name: "Ground Control 2",
                workout: STRINGS.GROUND_CONTROL_ID,
                isSecond: true,
            },
            {
                name: STRINGS.SHOULDER_CODEX_ID,
                workout: "",
                isSecond: false,
            },
            {
                name: "SHOULDER CODEX 2",
                workout: STRINGS.SHOULDER_CODEX_ID,
                isSecond: true,
            },
            {
                name: STRINGS.BALANCE_DAY,
                workout: "",
                isSecond: false,
            },
            {
                name: STRINGS.AREA,
                workout: "",
                isSecond: false,
            },
            {
                name: STRINGS.INFINITY_MODE,
                workout: "",
                isSecond: false,
            }
        ];
        //items=[];

        /*if(workout.length !==0){
            workout.forEach(raw=>{
              var body = {
                name: raw.workout.name,
                workout: raw.masterName ? raw.masterName : ""
              }
              items.push(body);
            });
          }*/

        // console.log("workout", user, workout, items);
        return (
            <section>
                <div className="section-3 top-buffer wf-section">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "./resources/Dashboard_files/603875204177f01840192a24_DashDude.png"
                        }
                        loading="lazy"
                        sizes="66vw"
                        srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/603875204177f01840192a24_DashDude-p-500.png 500w,
              https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/603875204177f01840192a24_DashDude-p-800.png 800w, 
              https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/603875204177f01840192a24_DashDude-p-1080.png 1080w, 
              https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/603875204177f01840192a24_DashDude.png 1500w"
                        alt=""
                        className="image-13"
                    ></img>

                    <div className="heading-welcome">
                        <h1 className="heading left-float">Welcome</h1>
                        <h1
                            ms-data="first-name"
                            className="heading right-float"
                        >
                            {user.username ? user.username : ""}
                        </h1>
                    </div>
                    <div className="div-block-17">
                        <h1 className="timer">{dailyTimer}</h1>
                    </div>
                </div>
                <div>
                    <Carousel className="carousel-container"
                              indicatorIconButtonProps={{
                                  style: {
                                      padding: "10px", // 1
                                      color: "white", // 3
                                  },
                              }}
                              navButtonsAlwaysVisible={true}
                              indicators={false}
                              swipe={true}
                              animation={"slide"}
                              autoPlay={false}
                    >
                        {workout.map((raw, idx) => {
                            return (
                                <Workout
                                    workout={raw}
                                    setWorkout={this.setWorkout}
                                    subscriptions={subscriptions}
                                    startProgram={this.startProgram}
                                    refresh={this.getWorkoutByUser}
                                ></Workout>
                            );
                        })}
                    </Carousel>
                </div>
                {/***/}
                <div className={"section-3 top-buffer-2 wf-section"}>
                    <div className={"div-block-11 gc _2"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/ground-control.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-10 image-10a"}
                        />
                        <h1 className={"heading-2"}>Ground Control</h1>
                        <p className={"paragraph"}>
                            Ground Control consists of holding early developmental milestone positions for time. These milestone positions are the building blocks of movement which we all learn in infancy. However once we learn to walk and run, we rarely go back to practice them.
                            <br/><br/>
                            When we incur injuries, our brain establishes protective patterns, which are essentially like turning on the emergency brakes of movement. Trying harder to pattern in new movements can actually reinforce these protective patterns, trapping us in a pain-loop of chronic dysfunction and pain.
                            <br/><br/>
                            Using functional meditation, we can reconnect to these early movement milestones creating easier, automatic movement and a better understanding of our individual movement signatures.

                        </p>
                    </div>
                    <div className={"div-block-16"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                    </div>
                    <div className={"div-block-11 gc _2"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/shoulder-codex.png"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-10 image-10a"}
                        />
                        <h1 className={"heading-2"}>Shoulder Codex</h1>
                        <p className={"paragraph"}>
                            The Shoulder Codex is a course specifically designed for individuals with chronic shoulder pain or dysfunction. It leads the participant through technical shoulder and global stability positions with the goal of unloading the cervical spine and engaging the shelf, where the shoulder complex is postured onto the body.
                            <br/><br/>
                            The program is progressive and increases in difficulty as the participants stability endurance increases. Participants should expect shaking and aching as the time under tension will challenge the individual. As the positions become increasingly difficult to hold, arm positions are switched. There are 7 shoulder positions in this course.

                        </p>
                    </div>
                    <div className={"div-block-16"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                    </div>
                    <div className={"div-block-11 bd _2"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/5fcae5969182d404ca34d3fa_BalanceDayv2.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-10 image-10a"}
                        />
                        <h1 className={"heading-2"}>Centrepoint</h1>
                        <p className={"paragraph"}>
                            Set yourself straight by going-on autopilot. This course is designed to allow the sub-programs of balance come to the forefront. It is vestibular and cerebellar training, challenging your body's ability to stand over a minimized base of support.
                            <br/><br/>
                            It is on the opposite spectrum of challenge compared to Ground Control and the Shoulder Codex, however it is not easy in it's own right. Learn to access your body's self rightening reflexes and find a more solid, balanced connection to the ground.

                        </p>
                    </div>
                    <div className={"div-block-16"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                    </div>
                    <div className={"div-block-11 bd _2"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/aera.png"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-10 image-10a"}
                        />
                        <h1 className={"heading-2"}>AERA</h1>
                        <p className={"paragraph"}>
                            Approximated End Range Activation is a program that enters the strange world of muscle cramping - and it's not for everyone. Learn how engaging into a cramp is beneficial for your motor control. And it's a secret weapon for chronic pain.
                            <br/><br/>
                            This program further divides into 3-sub programs; framing, solidify and phasing.
                            <br/><br/>
                            To unlock this program, the participant must finish GC1/2, Shoulder Codex 1/2 and Centrepoint.

                        </p>
                    </div>
                    <div className={"div-block-16"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/Dashboard_files/602b027c085a722e68e757ca_Down-arrowwhite.svg"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-12"}
                        />
                    </div>
                    <div className={"div-block-11 bd _2"}>
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/resources/infinity.png"
                            }
                            loading="lazy"
                            alt=""
                            className={"image-10 image-10a"}
                        />
                        <h1 className={"heading-2"}>Infinity Mode</h1>
                        <p className={"paragraph"}>
                            Once all the programs have been completed, infinity mode will randomly provide an assignment from previous programs.
                        </p>
                    </div>
                </div>
            </section>
        );
    }
}

DashboardPage.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        dogetWorkoutByUserMail: (params, callback) =>
            dispatch(getWorkoutByUserMail(params, callback)),
        docreateWorkout: (params, callback) =>
            dispatch(createWorkout(params, callback)),
        doStartWorkout: (params, callback) =>
            dispatch(startWorkout(params, callback)),
        doupdateWorkout: (params, callback) =>
            dispatch(updateWorkout(params, callback)),
        dogetUserByMail: (params, callback) =>
            dispatch(getUserByMail(params, callback)),
        doGetMemberships: (params, callback) =>
            dispatch(getMemberships(params, callback)),
    };
};

const connectedDashboardPage = withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, {
        pure: false,
    })(withStyles(styles)(DashboardPage))
);

export {connectedDashboardPage as DashboardPage};
