import * as actionTypes from "../../../../redux/actions/actionTypes";

const initialState = {
  loading: [],
  message: "",
};

export function ProgressIndicatorReducer (state = initialState, action) {

  switch (action.type) {
    case actionTypes.LOADING_START:
      return {
        ...state,
        loading: state.loading.concat({ [action.loading]: true }),
        message: action.message,
      };
    case actionTypes.LOADING_FINISH:
      return {
        ...state,
        loading: state.loading.filter((load) => !load[action.loading]),
      };
    default:
      return state;
  }
};

