import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class GetLeadersApi extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};
  url = BE_NEURO_URL+"common/leaders/";

  constructor(top) {
    super();
    this.url = this.url + top;
    this.headers = {
      'content-type': 'application/json'
    } 
    
    console.log("GetLeadersApi-",this.url,top);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
