import React,{Component} from "react";
//import clsx from "clsx";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import { ThemeProvider } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import theme from "./theme";

import logo from './logo.svg';
import './App.css';
import './neuro.css';

//components
import {ProgressIndicator} from './component/common/ProgressIndicator/ProgressIndicator';
import {ErrorApiIndicator} from './component/common/ErrorApiIndicator/ErrorApiIndicator';
import { Navbar } from './component/template/navbar'
import { FooterBar } from './component/template/footer'


//Constants
import STRINGS from "./constants/Strings";
import {routeAccess} from "./constants/Routes";

import withCommunication from "./hoc/communication/axios";

class App extends Component {
  constructor(props) {
    super(props);
    this.state={
    }
  }

  render() {
    const baseUri= "/";
    const { classes } = this.props;

    return (
        <Router getUserConfirmation={(message, callback) => {
            const allowTransition = window.confirm(message);
          callback(allowTransition);
          }}
        >
          <ProgressIndicator />
          <ErrorApiIndicator />
          <ThemeProvider theme={theme}>
          <Navbar />
          <div className="App">
            <div className="app-body">
                <Switch>
                  {routeAccess.map((raw, index) => (
                     <Route exact path={raw.path} key={index} component={raw.component} />
                  ))}
                  <Redirect to={baseUri} /> 
                </Switch>
              </div>
          </div>
          <FooterBar />
            <div className={"version-box"}>
              <span className={"version"}>{process.env.REACT_APP_VERSION}</span>
            </div>
          </ThemeProvider> 
      </Router>
    );
  }
}


App.propTypes = {
  classes: PropTypes.object.isRequired,

};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
     
    },
    dispatch
  );
};

const mapStateToProps = (state) =>{
  return state;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
