import * as actionTypes from "../../../redux/actions/actionTypes";

export const register = (params, callback = () => {}) => ({
  type: actionTypes.REGISTER_USER,
  data: {
    user: params.user,
  },
  callback,
});

export const login = (params, callback = () => {}) => ({
  type: actionTypes.LOGIN_USER,
  data: {
    user: params.user,
  },
  callback,
});

export const getUserByMail = (params, callback = () => {}) => ({
  type: actionTypes.GET_USER_BY_MAIL,
  data: {
    mail: params.mail,
  },
  callback,
});

export const updateUser = (params, callback = () => {}) => ({
  type: actionTypes.UPDATE_USER,
  data: {
    user: params.user,
  },
  callback,
});

export const changePassword = (params, callback = () => {}) => ({
  type: actionTypes.CHANGE_USER_PASSWORD,
  data: {
    mail: params.mail,
  },
  callback,
});

export const unsubscribeEmail = (params, callback = () => {}) => ({
  type: actionTypes.UNSUBSCRIBE_EMAIL,
  data: {
    mail: params.mail,
  },
  callback,
});
