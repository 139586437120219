import React, { useState,Component } from 'react'
import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
  } from '@reduxjs/toolkit'

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';  

//Material
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

//Components
import {ProfileTab} from "./profile.tab";
import {MembershipTab} from "./membership.tab";
import {DialogMessage} from "../dialog-message/dialog.message";

//Action
import {
    getUserByMail,
    updateUser
} from "../registration/actions/UserAction";
  
//Constants
import STRINGS from "../../constants/Strings";
import { config } from '../../constants/Config';

import './styles/profile.css';
import qr from "query-string";

const styles = (theme) => ({
    root: {
       
    },
    containerTop:{
      marginTop:50,
    },
    
});

  
class ProfilePage extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        showRegistration: false,
        tab:{
            profile: true,
            membership: false
        },
        user:{},
        msgbox: {
            showMsg: false,
            msgTitle: '',
            msgBody: ''
          },
      }
    }

    componentDidMount() {
        this.getUser();
        const params = qr.parse(this.props.location.search);
        if(params.register){
            this.setState({
                tab : {
                    profile: false,
                    membership: true
                },
                register: params.register,
            });
        } else if(params.tab){
            this.setState({
                tab : {
                    profile: false,
                    membership: true
                }
            });
        }
    }

    getUser() {
        const { dogetUserByMail } = this.props;
        const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);
 
        dogetUserByMail({mail:mail},(user)=>{
            console.log("getUser",user);
            if(user){
              this.setState({user: user});
            }
          });
    }

    handleChangebtn=(event)=>{
      const value = event.currentTarget.value;
      this.setState({title:"test2"});
    }

    viewRegistration=(event)=>{
      this.setState(
        {
          showRegistration: true
        }
      );
    }
    
    handleProfile = (event) => {
      const {tab,user} = this.state;
      this.getUser();
      this.setState({
          tab : {
            profile: true,
            membership: false
          }
      });
    }

    handleMembership = (event) => {
        this.getUser();
        const {tab,user} = this.state;
        console.log("handleMembership",user);
        this.setState({
            tab : {
              profile: false,
              membership: true
            }
        });
    }

    logOut = (event) => {

        sessionStorage.removeItem(STRINGS.APP_TOKEN);
        sessionStorage.removeItem(STRINGS.APP_USERNAME);
        this.goToHome();
    }

    goToHome = (event) => {
        console.log("goToHome");
        const url = config.getBaseUri();
        window.location.href = url + "/";
    }

    handleUpdateProfile = (user) => {
        const{doupdateUser} = this.props;
        console.log("handleUpdateProfile",user);
        const param = {
            id: user.id,
            username: user.username,
            mail: user.mail,
            password: user.password
        }
        if(user.password && user.confirm === user.password){
            doupdateUser({user:param},(res)=>{
                console.log("handleUpdateProfile".param,res);
                if(res && res.code === 200){
                    this.setState({
                        msgbox: {
                            showMsg: true,
                            msgTitle: 'Success',
                            msgBody: 'User profile has been updated'
                        },
                    });
                }
            });
        }else{
            this.setState({
                msgbox: {
                    showMsg: true,
                    msgTitle: 'User Profile',
                    msgBody: 'Password and confirm do not much'
                },
            });
        }
    } 

    handleCloseDM = (event) => {
        this.setState({
            msgbox: {
                showMsg: false,
                msgTitle: '',
                msgBody: ''
            },
        });
    };

    render() {
      const { classes } = this.props;
      const {
        tab,
        user,
          register,
      } = this.state;

      return (
          <section>
              {/***/}
              <Container className={"profile-background"} maxWidth="md">
                  <Typography variant="h6">
                        Manage Profile
                   </Typography>
                   <br />
                  <Paper style={{padding:10}}>
                      <Grid container spacing={1}>
                          <Grid item xs={4} md={2}>
                              <Grid container spacing={1}>
                                  <Grid item xs={12}></Grid>
                                  <Grid item xs={12}>
                                      <Button variant="text" onClick={(event) => this.handleProfile(event)} >Profile</Button>
                                  </Grid>
                                  <Grid item xs={12}><Button variant="text" onClick={(event) => this.handleMembership(event)} >Membership</Button> </Grid>
                                  <Grid item xs={12}><Button variant="text" onClick={(event) => this.logOut(event)} >Logout</Button> </Grid>
                              </Grid>
                          </Grid>
                          <Grid item xs={8} md={10} style={{paddingLeft: 10}}>
                              {tab.profile &&
                                <>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <ProfileTab
                                            user={user}
                                            handleUpdateProfile={this.handleUpdateProfile}
                                        ></ProfileTab> 
                                    </Grid>
                                </Grid>
                                </>
                              }
                              {tab.membership &&
                                <>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <MembershipTab
                                          user={user}
                                          subscriptionType={register}
                                        ></MembershipTab> 
                                    </Grid>
                                </Grid>
                                </>
                              }
                          </Grid>
                      </Grid>
                  </Paper>
                  <DialogMessage
                    showMsg={this.state.msgbox.showMsg}
                    msgTitle={this.state.msgbox.msgTitle}
                    msgBody={this.state.msgbox.msgBody}
                    handleClose={this.handleCloseDM}
                    name={this.state.msgbox.name}
                    type={this.state.msgbox.type}
                    data={this.state.msgbox.data}
                    handleOk={this.handleOkDialogMessage}
                    ></DialogMessage>
              </Container>
          </section>
          
      );
    }
}

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) =>{
    return {
      dogetUserByMail: (params,callback) => dispatch(getUserByMail(params,callback)),
      doupdateUser: (params,callback) => dispatch(updateUser(params,callback)),
    }
  }

const connectedProfilePage = withRouter(connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(withStyles(styles)(ProfilePage)));

export { connectedProfilePage as ProfilePage };
