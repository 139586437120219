import React, { useState, Component, forwardRef } from "react";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Moment from "moment";

//Material
import { withStyles } from "@material-ui/core/styles";
import {
  compose,
  flexbox,
  spacing,
  palette,
  positions,
} from "@material-ui/system";
import { Grid } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

//Icon
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

//component
import { DialogMessage } from "../dialog-message/dialog.message";

//validators
import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "../../constants/ValidationList";

//Action
import { sendEmail } from "../profile/actions/ProfileAction";

//Action
import { changePassword } from "../registration/actions/UserAction";

//Constants
import STRINGS from "../../constants/Strings";
import MODALMESSAGES from "../../constants/Messages";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
  table: {
    minWidth: 650,
    marginTop: 20,
  },
  buttonSubmit: {
    width: 200,
  },
  paper: {
    position: "absolute",
    maxWidth: "65%",
    minWidth: "40%",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #grey",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1),
  },
  duration: {
    border: "1px solid grey",
    height: 50,
    marginBottom: 10,
    padding: 10,
  },
  updateBorder: {
    border: "1px solid grey",
    minHeight: 200,
  },
  meetingChip: {
    marginLeft: 5,
  },
  meetingBox: {
    height: 20,
  },
  labelRow: {
    display: "flex",
    justifyContent: "flex-end",
    alignrecipients: "center",
    height: 20,
    fontSize: 14,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SUBMIT_CHANGE_PASSWORD = "SUBMIT_CHANGE_PASSWORD";

class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipients: [],
      value: "",
      isFormValid: false,
      errors: {
        usernameError: [],
        emailError: [],
        passwordError: [],
      },
      user: {
        mail: "",
        username: "",
        password: "",
      },
      msgbox: {
        showMsg: false,
        msgTitle: "",
        msgBody: "",
      },
    };
  }

  componentDidMount() {}

  handleCloseModal = (event) => {
    if (this.props.handleCloseModal) {
      this.props.handleCloseModal(event);
    }
  };

  handleChange = (prop) => (event) => {
    const { user } = this.state;
    if (event.target) {
      user[prop] = event.target.value;
    } else {
      user[prop] = event;
    }
    this.setState({ user: user });
  };

  showErrorMessage = (list) => {
    if (list.length === 0) return "";

    return list[0];

    let msg = "";

    msg = Object.values(list).join(", "); //.map( l => msg += l);

    return msg;
  };

  onSubmitForgot = (event) => {
    this.doSubmitChangePassword();
    // this.setState({
    //   msgbox: {
    //     name: SUBMIT_CHANGE_PASSWORD,
    //     type: "confirm",
    //     showMsg: true,
    //     msgTitle: "Confirm",
    //     msgBody: STRINGS.SIGNIN.CHANGE_PASSWORD,
    //   },
    // });
  };

  handleOkDialogMessage = (result, name) => {
    if (result && name === SUBMIT_CHANGE_PASSWORD) {
      this.setState({
        inputErrors: {},
        msgbox: {
          showMsg: false,
          msgTitle: "",
          msgBody: "",
        },
      });
      this.doSubmitChangePassword();
    } else {
      this.setState({
        msgbox: {
          showMsg: false,
          msgTitle: "",
          msgBody: "",
        },
      });
    }
  };

  doSubmitChangePassword() {
    const { doChangePassword } = this.props;
    const { user } = this.state;
    console.log("ChangePasswordSaga", user);
    if (user && user?.mail !== "") {
      doChangePassword({ mail: user.mail }, (result) => {
        console.log(result);
        if (result && result.code >= 200) {
          this.setState({
            msgbox: {
              showMsg: true,
              msgTitle: "Success",
              msgBody: "Please check your email for your new credentials",
            },
          });
        }
        if (result && result.code === 404) {
          this.setState({
            msgbox: {
              showMsg: true,
              msgTitle: "Sorry!",
              msgBody: "Couldn't find your account",
            },
          });
        }
      });
    }
  }

  handleCloseDM = (event) => {
    this.setState({
      msgbox: {
        showMsg: false,
        msgTitle: "",
        msgBody: "",
      },
    });
    if (this.props.handleCloseModal) {
      this.props.handleCloseModal(event);
    }
  };

  render() {
    const { classes, recipients, showModal } = this.props;

    const { user, errors } = this.state;

    let meetingRecipients = [];
    if (this.state.recipients.length === 0) {
      meetingRecipients = recipients;
    } else {
      meetingRecipients = this.state.recipients;
    }

    return (
      <section>
        <Dialog
          open={showModal}
          onClose={(event) => this.handleCloseModal(event)}
          aria-labelledby="customized-dialog-title"
          scroll="paper"
          TransitionComponent={Transition}
          classes={{ paper: classes.paper }}
        >
          <Grid container spacing={1}>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>
              <Box display="flex" justifyContent="center">
                <Typography gutterBottom variant="h6">
                  Forgot Password
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  color="primary"
                  ria-label="upload picture"
                  component="span"
                  onClick={(event) => this.handleCloseModal(event)}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <TextField
                    disabled={false}
                    label="Email Address"
                    fullWidth
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{ className: classes.inputDetails }}
                    variant="outlined"
                    onChange={this.handleChange("mail")}
                    value={user?.mail ? user?.mail : ""}
                    error={errors.emailError.length > 0}
                    helperText={this.showErrorMessage(errors.emailError)}
                  />
                </Box>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(event) => this.onSubmitForgot(event)}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
            <Button
              onClick={(event) => this.handleCloseModal(event)}
              variant="contained"
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <DialogMessage
          showMsg={this.state.msgbox.showMsg}
          msgTitle={this.state.msgbox.msgTitle}
          msgBody={this.state.msgbox.msgBody}
          handleClose={this.handleCloseDM}
          name={this.state.msgbox.name}
          type={this.state.msgbox.type}
          data={this.state.msgbox.data}
          handleOk={this.handleOkDialogMessage}
        ></DialogMessage>
      </section>
    );
  }
}

ForgotPasswordModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    doChangePassword: (params, callback) =>
      dispatch(changePassword(params, callback)),
    doSendEmail: (params, callback) => dispatch(sendEmail(params, callback)),
  };
};

const connectedForgotPasswordModal = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
  })(withStyles(styles)(ForgotPasswordModal))
);

export { connectedForgotPasswordModal as ForgotPasswordModal };
