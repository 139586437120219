const SERVERERROR = {
  100001: "App Error",
  400: "App Error",
  404: "App Error",
  500: "App Error",
  999: "Session timeout. Please login."
};

const getErrorMessageFromErrorCode = (code, errorObj) => {
  console.log("code",code,errorObj);
 
  if (SERVERERROR[code]) {
    // alert(errorObj.message);
    return errorObj;
  }

  if (errorObj?.message) {
    
    return errorObj;
  }
  return errorObj;
};

export default getErrorMessageFromErrorCode;
