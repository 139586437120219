import React, { useState, Component } from "react";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Material
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
import "./styles/contact.css";
import {getUserByMail, updateUser} from "../registration/actions/UserAction";
import {contactEmail} from "./actions/ContactAction";
import {DialogMessage} from "../dialog-message/dialog.message";
import STRINGS from "../../constants/Strings";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class ContactPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      msgbox: {
        showMsg: false,
        msgTitle: "",
        msgBody: "",
      },
    };
  }

  handleChange = (prop) => (event) => {
    const { contact } = this.state;
    if (event.target) {
      contact[prop] = event.target.value;
    } else {
      contact[prop] = event;
    }
    this.setState({ contact: contact });
  };

  submit = () => {
    const {doContactEmail} = this.props;
    const {contact} = this.state;
    doContactEmail({email: {
      ...contact
      }}, res => {
      console.log(res);
      this.setState({contact: {},msgbox: {
          showMsg: true,
          msgTitle: "Info",
          msgBody: STRINGS.CONTACT.SENT,
        },});
    })
  }

  handleCloseDM = (event) => {
    this.setState({
      msgbox: {
        showMsg: false,
        msgTitle: "",
        msgBody: "",
      },
    });
  };

  handleOkDialogMessage = (event) => {
    this.setState({
      msgbox: {
        showMsg: false,
        msgTitle: "",
        msgBody: "",
      },
    });
  };

  render() {
    const { classes } = this.props;
    const { contact } = this.state;
    return (
      <section>
        {/***/}
        <h1 className={"hero-head"}>CONTACT US</h1>
        <div className={"section-5 wf-section"} id="Buy-sec">
          <div className={"width-div white"}>
            <h2 className={"heading-2 black"}>
              Want to find out if this is for you?
            </h2>
            <p className={"paragraph center black"}>
              The best athletes are doing this program, quietly. We trained the
              Chinese Speed skating team resulting in 3 Olympic gold medals.
              Worked with medalling World Cup athletes and champion professional
              and university teams.
            </p>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" className={"contact-box"}>
                    <TextField
                      disabled={false}
                      label="Name"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ className: classes.inputDetails }}
                      variant="outlined"
                      onChange={this.handleChange("name")}
                      value={contact?.name ? contact?.name : ""}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" className={"contact-box"}>
                    <TextField
                      disabled={false}
                      label="Email Address"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ className: classes.inputDetails }}
                      variant="outlined"
                      onChange={this.handleChange("email")}
                      value={contact?.email ? contact?.email : ""}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center" className={"contact-box"}>
                    <TextField
                      disabled={false}
                      label="Message"
                      fullWidth
                      InputLabelProps={{
                        shrink: true,
                      }}
                      multiline
                      maxRows={5}
                      minRows={3}
                      inputProps={{ className: classes.inputDetails }}
                      variant="outlined"
                      onChange={this.handleChange("message")}
                      value={contact?.message ? contact?.message : ""}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <button
                    variant="contained"
                    className={"submit-button w-button"}
                    onClick={this.submit}
                  >
                    Submit
                  </button>
                </Grid>
              </Grid>
          </div>
        </div>
        <DialogMessage
            showMsg={this.state.msgbox.showMsg}
            msgTitle={this.state.msgbox.msgTitle}
            msgBody={this.state.msgbox.msgBody}
            handleClose={this.handleCloseDM}
            name={this.state.msgbox.name}
            type={this.state.msgbox.type}
            data={this.state.msgbox.data}
            handleOk={this.handleOkDialogMessage}
        ></DialogMessage>
      </section>
    );
  }
}

ContactPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) =>{
  return {
    doContactEmail: (params,callback) => dispatch(contactEmail(params,callback)),
  }
}

const connectedContactPage = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
  })(withStyles(styles)(ContactPage))
);

export { connectedContactPage as ContactPage };
