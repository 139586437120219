import React, { useState, Component } from "react";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import clsx from "clsx";

//Material
import Box from "@material-ui/core/Box";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Link as RouterLink } from "react-router-dom";
import { Route, MemoryRouter } from "react-router";
import { Grid } from "@material-ui/core";
//
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../../theme";

//Constants
import STRINGS from "../../constants/Strings";
import { routeAccess } from "../../constants/Routes";

import { LoginModal } from "../registration/login.modal";
//config
import { config } from "../../constants/Config";

const drawerWidth = 160;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
});

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideMenu: false,
      disableMenu: true,
      disableVpApproval: true,
      showLogin: false,
      user: {},
      isLoggedIn: false,
    };
  }

  componentDidUpdate(prevProps) {}

  handleDrawerOpen = (value) => {
    this.setState({
      openSideMenu: true,
    });
  };

  handleDrawerClose = (value) => {
    this.setState({
      openSideMenu: false,
    });
  };

  handleOpenLink = (event) => {
    console.log(event.currentTarget);
  };

  handleCloseModal = (event) => {
    this.setState({
      showLogin: false,
    });
  };

  viewLogin = (event) => {
    console.log("viewLogin");
    this.setState({
      showLogin: true,
    });
  };

  logOut = (event) => {
    console.log("logOut");
    sessionStorage.removeItem(STRINGS.APP_TOKEN);
    this.setState({
      isLoggedIn: false,
    });
  };

  isLoggedIn = () => {
    const { isLoggedIn } = this.state;
    console.log("isLoggedIn", isLoggedIn);

    const token = sessionStorage.getItem(STRINGS.APP_TOKEN);
    if (token && token !== "" && !isLoggedIn) {
      this.setState({
        isLoggedIn: true,
      });
    }
    console.log("isLoggedIn");
  };

  goToHome = (event) => {
    const { history } = this.props;
    history.push({
      pathname: "/",
    });
  };

  goToDashboard = (event) => {
    console.log("goToDashboard");
    const { history } = this.props;
    const url = config.getBaseUri();
    console.log("goToHome", url);
    history.push({
      pathname: url + "/dashboard",
    });
  };

  render() {
    const { classes } = this.props;
    const { openSideMenu, showLogin, user, isLoggedIn } = this.state;

    const baseUri = "/";

    function HomeIcon(props) {
      return (
        <SvgIcon {...props}>
          <path d="../../" />
        </SvgIcon>
      );
    }

    this.isLoggedIn();

    console.log("isLoggedIn", isLoggedIn, config.getBaseUri);

    return (
      <>
        <div className={classes.root}>
          <CssBaseline />
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openSideMenu,
            })}
          >
            <Grid container>
              <Grid item xs={12}>
                <div
                  className={"top wf-section"}
                  data-w-id="f6a69b23-5cc4-8e48-ff5a-04285d46c0f5"
                >
                  <a
                    className={"link-block icon w-inline-block"}
                    href="#"
                    onClick={(event) => this.goToHome(event)}
                  >
                    <img
                      alt=""
                      src={
                        process.env.PUBLIC_URL +
                        "/resources/5fc6a87e91b09015584c07c1_NG-White.svg"
                      }
                      loading="lazy"
                    />
                  </a>

                  {(isLoggedIn && (
                    <>
                      <a
                        className={"text-block div-link"}
                        onClick={(event) => this.goToDashboard(event)}
                      >
                        Dashboard
                      </a>
                    </>
                  )) || (
                    <>
                      <a
                        className={"text-block div-link"}
                        onClick={(event) => this.viewLogin(event)}
                      >
                        Log In
                      </a>
                    </>
                  )}
                </div>
              </Grid>
            </Grid>
          </AppBar>
        </div>

        <LoginModal
          showLogin={showLogin}
          user={user}
          handleCloseModal={this.handleCloseModal}
        ></LoginModal>
      </>
    );
  }
}

Navbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedNavbar = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Navbar))
);
export { connectedNavbar as Navbar };
