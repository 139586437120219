import React, { useState,Component } from 'react'
import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
  } from '@reduxjs/toolkit'

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';  

//Material
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

//Components
//import {DialogMessage} from "../dialog-message/dialog.message";

import './styles/profile.css';

const styles = (theme) => ({
    root: {
       
    },
    containerTop:{
      marginTop:50,
    },
    
});

  
class ProfileTab extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        showRegistration: false,
        user:{},
        msgbox: {
            showMsg: false,
            msgTitle: '',
            msgBody: ''
          },
      }
    }

    componentDidUpdate(prevProps) {
        const {user} = this.props;
        if(user !== prevProps.user){
            this.setState({user: user});
        }
    }

    handleChangebtn=(event)=>{
      const value = event.currentTarget.value;
      this.setState({title:"test2"});
    }

    viewRegistration=(event)=>{
      this.setState(
        {
          showRegistration: true
        }
      );
    }
    
    handleCloseModal = (event) => {
      this.setState(
        {
          showRegistration: false
        }
      );
    }

    handleSubmit = (event) => {
        const {handleUpdateProfile} = this.props;
        const {user} = this.state;

        if(handleUpdateProfile){
            handleUpdateProfile(user);
        }
    }

    handleChange = prop => event => {
        const { user } = this.state;

        if (event.target) {
            user[prop] = event.target.value;
        } else {
            user[prop] = event;
        }

        this.setState({user: user});
    }

    render() {
      const { classes } = this.props;
      const {
        user
      } = this.state;

      return (
          <section>
              {/***/}
              <Container maxWidth="md">
                  <Typography variant="h6">My Information</Typography><br />
                  <Grid container spacing={1}>
                     <Grid item xs={12}>
                          <TextField
                              disabled={false}
                              label="Username"
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              variant="outlined"
                              onChange={this.handleChange('username')}
                              value={(user.username) ? user.username : ""}
                          />
                      </Grid>
                      <Grid item xs={12}>
                          <TextField
                              disabled={false}
                              label="Mail"
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              variant="outlined"
                              onChange={this.handleChange('mail')}
                              value={(user.mail) ? user.mail : ""}
                          />
                      </Grid>
                      <Grid item xs={12}><Typography variant="h6">Change Password</Typography></Grid>
                      <Grid item xs={12}>
                        <TextField
                              disabled={false}
                              label="New Password"
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              variant="outlined"
                              type="password"
                              onChange={this.handleChange('password')}
                              value={(user.password) ? user.password : ""}
                          />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                              disabled={false}
                              label="Confirm"
                              fullWidth
                              InputLabelProps={{
                                  shrink: true,
                              }}
                              variant="outlined"
                              type="password"
                              onChange={this.handleChange('confirm')}
                              value={(user.confirm) ? user.confirm : ""}
                          />
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" onClick={(event) => this.handleSubmit(event)} >Update</Button>
                      </Grid>
                  </Grid>
              </Container>
          </section>
      );
    }
}

ProfileTab.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedProfileTab = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(withStyles(styles)(ProfileTab)));

export { connectedProfileTab as ProfileTab };
