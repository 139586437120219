import { getApiImplementation } from "../BaseApi";
import { URLS } from "../ApiUrls";

export default class BaseApiRequest {
  url = "";
  headers = "";

  constructor() {
    this.url = this.getBaseUrl();
  }

  getData = () => {
    const body = JSON.parse(JSON.stringify(this.getBody()));
    console.log("BaseApiRequest",
      this.getMethod(),
      this.getUrl(),
      body,
      this.getheaders()
    );

    console.log(getApiImplementation());
    return getApiImplementation()
      .callApi(this.getMethod(), this.getUrl(), body, this.getheaders())
      .then((response) => {
        console.log("getApiImplementation",response);
        if (!response) {
          throw new Error("Session timeout. Please login.");
        }
        return response;
      })
      .catch((error) => {
        console.log("getApiImplementation",error);
        throw error;
      });
  };

  getBaseUrl = () => {
    let baseURL = URLS.BASE_URL;
    console.log("window.location",window.location);
    console.log("baseURL",baseURL);

    return baseURL;
  };

  getheaders = () => {
    return this.headers;
  };

}
