import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// styles
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";

import { unsubscribeEmail } from "../registration/actions/UserAction";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class UnsubscribePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const email = params.get("email") ?? null;

    if (email !== null) this.handleUnsubscribe(email);

    console.log("UNSUBSCRIBE EMAIL", email);
  }

  handleUnsubscribe = (email) => {
    const { doUnsubscribeEmail } = this.props;
    const params = {
      mail: email,
    };
    doUnsubscribeEmail(params, (result) => {
      console.log("UNSUBSCRIBE EMAIL RESULT", result);
      if (result && result.code >= 200) {
        this.setState({ isValid: true });
      }
    });
  };

  render() {
    const { isValid } = this.state;
    return (
      <Grid container spacing={50}>
        <Grid item xs={12} classes={"center"}>
          <Typography className={"policy-header"}>
            {isValid
              ? `You have been unsubscribed on any future email notifications.`
              : `Email not found`}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

UnsubscribePage.propTypes = {};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    doUnsubscribeEmail: (params, callback) =>
      dispatch(unsubscribeEmail(params, callback)),
  };
};

const connectedUnsubscribePage = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
  })(withStyles(styles)(UnsubscribePage))
);

export { connectedUnsubscribePage as UnsubscribePage };
