import React, { useState,Component } from 'react'
import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
  } from '@reduxjs/toolkit'

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';  
import qr from 'query-string';

//Material
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

//Components
import {ProfileTab} from "./profile.tab";
import {MembershipTab} from "./membership.tab";
import {DialogMessage} from "../dialog-message/dialog.message";

//Action
import {
  createPortalSession
} from "./actions/ProfileAction";

import {
  getUserByMail,
  updateUser
} from "../registration/actions/UserAction";

//Constants
import STRINGS from "../../constants/Strings";
import { config } from '../../constants/Config';

import './styles/profile.css';
import {completePayment} from "../payment/actions/PaymentAction";

const styles = (theme) => ({
    root: {
       
    },
    containerTop:{
      marginTop:50,
    },
    
});

  
class PaymentSuccessPage extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        showRegistration: false,
        tab:{
            profile: true,
            membership: false
        },
        user:{},
        msgbox: {
            showMsg: false,
            msgTitle: '',
            msgBody: ''
          },
      }
    }

    componentDidMount() {
        const {doCompletePayment} = this.props;
        let params = qr.parse(this.props.location.search);
        console.log("componentDidMount",params.session_id);
        if(params.session_id){
            doCompletePayment(params.session_id);
        }

    }

    
    handleCloseDM = (event) => {
        this.setState({
            msgbox: {
                showMsg: false,
                msgTitle: '',
                msgBody: ''
            },
        });
    };

    handleBilling = (event) => {
      const {doCreatePortalSession,dogetUserByMail} = this.props;
      let params = qr.parse(this.props.location.search);
      const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);
      const priceLookup = {
        sessionId: params.session_id,
        userMail : mail
      }

      doCreatePortalSession({priceLookup:priceLookup},(res)=>{
        console.log("doCreatePortalSession",res);
        if(res && res.url){
            window.location.href=res.url;
        }
      });
    }

  getUser() {
    const { dogetUserByMail } = this.props;
    const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);

    dogetUserByMail({ mail: mail }, (user) => {
      console.log("dogetUserByMail", user);
      if (user) {
        this.setState({ user: user });
      }
    });
  }

    render() {
      const { classes } = this.props;
      const {
        tab,
        user
      } = this.state;

      return (
          <section>
              {/***/}
              <Container className={"profile-background"} maxWidth="md">
                  <Typography variant="h6">
                      Subscription successful!
                   </Typography>
                   <br />
                  <Paper style={{padding:10}}>
                      <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Button variant="contained" onClick={(event)=>this.handleBilling(event)} >Manage Your Billing</Button>
                          </Grid>
                      </Grid>
                  </Paper>
                  <DialogMessage
                    showMsg={this.state.msgbox.showMsg}
                    msgTitle={this.state.msgbox.msgTitle}
                    msgBody={this.state.msgbox.msgBody}
                    handleClose={this.handleCloseDM}
                    name={this.state.msgbox.name}
                    type={this.state.msgbox.type}
                    data={this.state.msgbox.data}
                    handleOk={this.handleOkDialogMessage}
                    ></DialogMessage>
              </Container>
          </section>
          
      );
    }
}

PaymentSuccessPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) =>{
    return {
      doCreatePortalSession: (params,callback) => dispatch(createPortalSession(params,callback)),
      dogetUserByMail: (params,callback) => dispatch(getUserByMail(params,callback)),
        doCompletePayment: (params,callback) => dispatch(completePayment(params,callback)),
    }
  }

const connectedPaymentSuccessPage = withRouter(connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(withStyles(styles)(PaymentSuccessPage)));

export { connectedPaymentSuccessPage as PaymentSuccessPage };
