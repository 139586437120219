import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class CreatePortalSessionApi extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {};
  url = BE_NEURO_URL+"payment/create-portal-session/";

  constructor(priceLookup) {
    super();
    this.body=priceLookup;

    console.log("CreatePortalSessionApi-",this.url,priceLookup);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
