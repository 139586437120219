import STRINGS from "./Strings";
import AppConstants from "./AppConstants";

const VALIDATIONSLIST = { 
  REGISTRATION: {
    mail: {
      fieldName: STRINGS.REGISTRATION.FORM.EMAILLABEL,
      required: true,
      pattern: AppConstants.REGISTRATION.EMAILPATTERN,
    },
    username: {
      fieldName: STRINGS.REGISTRATION.FORM.USERNAMELABEL,
      fieldType: "username",
      required: true,
      pattern: AppConstants.REGISTRATION.USERNAMEPATTERN,
    },
    password: {
      fieldName: STRINGS.SIGNIN.FORM.PASSWORDLABEL,
      fieldType: "password",
      required: true,
      pattern: AppConstants.REGISTRATION.PASSWORDPATTERN,
    },
  },
   
};

export default VALIDATIONSLIST;
