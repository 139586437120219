import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Material
import { Grid } from "@material-ui/core";

//Component
import { HowItWorks } from "./how.it.works";
import { ClinicallyProven } from "./clinically.proven";
import { Programs } from "./programs";
import Marquee from "react-fast-marquee";

//Constants
import STRINGS from "../../constants/Strings";

//Action
import { getLeaders } from "../dashboard/actions/WorkoutAction";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class Testimonial extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {}

  render() {
    const testimonialList = STRINGS.HOMEPAGE.TESTIMONIAL.LIST;

    return (
      <>
        <Grid item xs={12}>
          <div
            className={"section-2-hizon bottom-buffer wf-section"}
            data-w-id="ee67f121-4ad0-1e79-7d71-aa3157af5ddb"
          >
            <div className={"sticky"}>
              <div className={"width-div"}>
                <h1 className={"heading"}>
                  {STRINGS.HOMEPAGE.TESTIMONIAL.TITLE}
                </h1>
              </div>
              <div className={"overflow"}>
                <div className={"section-row"}>
                  <Marquee speed={30} gradientWidth={0}>
                    {testimonialList.map((item, idx) => (
                      <>
                        <div className={"width-div horizontal"} key={idx}>
                          <a
                            className="div-block-9 w-inline-block"
                            href={item.link}
                            target={"_blank"}
                          >
                            <h1 className={"heading-2 rotate"}>
                              {item.firstName}
                              <br /> {item.lastName}
                            </h1>
                            <div>
                              <p className={"paragraph smedia"}>
                                @{item.mediaUser}
                              </p>
                              <p className={"paragraph testim"}>
                                {item.testimonial}
                              </p>
                            </div>
                          </a>
                        </div>
                      </>
                    ))}
                  </Marquee>
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </>
    );
  }
}

Testimonial.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedTestimonial = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Testimonial))
);

export { connectedTestimonial as Testimonial };
