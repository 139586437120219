import React, { useState, Component } from 'react'
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  root: {

  }, containerTop: {
    marginTop: 50,
  },
  table: {
    minWidth: 650,
  },backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class ProgressIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: '',
    }
  };

  isRendered =(value)=>{
    if(this.props.isRendered){
      this.props.isRendered(value);
    }
  }

  render() {
    const { loading, message,classes,isRendered } = this.props;

    let spinner = null;
    if (loading.length > 0) {
      this.isRendered(false);

      spinner = (
        <>
          <Backdrop className={classes.backdrop} open={true} >
                  <CircularProgress color="primary" value={100} />
          </Backdrop>
        </>
      );
    }else{
      this.isRendered(true);
    }

    return spinner;
  };
}
ProgressIndicator.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.string,
};
ProgressIndicator.defaultProps = {
  loading: [],
  message: null,
};

const mapStateToProps = (state) => ({
  loading: state.ProgressIndicatorReducer.loading,
  message: state.ProgressIndicatorReducer.message,
});

const connectedProgressIndicator = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(withStyles(styles)(ProgressIndicator)));
export { connectedProgressIndicator as ProgressIndicator };