import * as actionTypes from "../../../../redux/actions/actionTypes";

const initialState = {
  code: "",
  error: "",
  message: "",
};

export function ErrorApiIndicatorReducer (state = initialState, action) {
  switch (action.type) {
    case actionTypes.APPLICATION_ERROR:
      return {
        ...state,
        error: action.error,
      };
    
    default:
      return state;
  }
};

