import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import {
    showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

  
import CreateCheckoutSessionApi from "../../../communication/api/CreateCheckoutSessionApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("CreateCheckoutSessionApi",action);
  const priceLookup = action.data.priceLookup;

  const createCheckoutSessionApi = new CreateCheckoutSessionApi(priceLookup);
  try {

    const result = yield call(createCheckoutSessionApi.getData);
    console.log("CreateCheckoutSessionApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("CreateCheckoutSessionApi",error);
    //yield put(showApiError(error.toString()));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.CREATE_CHECKOUT_SESSION, handleSaga);
}

export default sagaWatcher;
