import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import {
    showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

  
import GetLeadersApi from "../../../communication/api/GetLeadersApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("GetLeadersApi",action);
  const top = action.data.top;

  try {
    const getLeadersApi = new GetLeadersApi(top);
    const result = yield call(getLeadersApi.getData);
    console.log("GetLeadersApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("GetLeadersApi",error);
    yield put(showApiError(error.toString()));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.GET_LEADERS, handleSaga);
}

export default sagaWatcher;
