import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class CompletePaymentApi extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};
  url = BE_NEURO_URL+"payment/completePayment/";

  constructor(sessionId) {
    super();
    this.url = this.url + sessionId;
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
