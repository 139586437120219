import React, { useState,Component } from 'react'
import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
  } from '@reduxjs/toolkit'

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';  

//Material
import { withStyles } from '@material-ui/core/styles';
import { compose, flexbox, spacing, palette, positions } from '@material-ui/system';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
     
  },containerTop:{
    marginTop:50,
  },
  table: {
    minWidth: 650,
    marginTop:20
  },
  buttonSubmit:{
    width:200
  },paper: {
    minHeight:'20%',
    minWidth: '25%',
   
  },title:{
    height:20,

  }
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
class DialogMessage extends Component {

    constructor(props) {
        super(props);
        this.state = {
          name:'',
        }
    }

    handleOpen = (event) => {
        this.props.handleOpen(event);
    }

    handleClose = (event) => {
        this.props.handleClose(event);
    }

    handleCancel = (event) => {
      const {name,data} = this.props;
      this.props.handleCancel(true,name,data);
    }

    handleOk = (event) => {
      const {name,data} = this.props;
      this.props.handleOk(true,name,data);
    }

    render() {
        const { classes, type } = this.props;
        console.log("showMsg",this.props.showMsg);
        return (
                <section>
                    <Dialog 
                     open={this.props.showMsg}  
                     onClose={(event) => this.handleClose(event)}
                     aria-labelledby="customized-dialog-title"
                     TransitionComponent={Transition}
                     classes={{ paper: classes.paper}}
                    >
                      <DialogTitle id="customized-dialog-title" onClose={(event) => this.handleClose(event)}>
                        {this.props.msgTitle}
                      </DialogTitle>
                        <DialogContent dividers>
                         <Typography gutterBottom>
                                {this.props.msgBody}
                          </Typography>
                        </DialogContent>
                        <DialogActions>
                          {(type && type === "confirm") &&
                            <>
                            <Button onClick={(event) => this.handleOk(event)}  variant="contained" color="primary">
                              {(this.props.showYesNo) &&
                                "Yes"
                                ||
                                "Ok"
                              }
                            </Button>
                            {(this.props.enableCancel) &&
                              <>
                                <Button onClick={(event) => this.handleCancel(event)} variant="contained" color="primary">
                                {(this.props.showYesNo) &&
                                    "No"
                                    ||
                                    "Cancel"
                                  }
                                  
                                </Button>
                              </>
                              ||
                              <>
                                <Button onClick={(event) => this.handleClose(event)} variant="contained" color="primary">
                                  {(this.props.showYesNo) &&
                                    "No"
                                    ||
                                    "Cancel"
                                  }
                                </Button>
                              </>
                            }
                            </>
                            ||
                            <Button onClick={(event) => this.handleClose(event)}  variant="contained" color="primary">
                             {(this.props.showYesNo) &&
                                    "Yes"
                                    ||
                                    "Ok"
                                }
                            </Button>
                          }

                        </DialogActions>
                    </Dialog>
  
                </section>
        );
    }
}


DialogMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

DialogMessage.defaultProps = {
  type: "",
  name: "",
  data: "",
};

function mapStateToProps(state) {
    return state;
}

const connectedDialogMessage = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(withStyles(styles)(DialogMessage)));

export { connectedDialogMessage as DialogMessage };
