import React, { useState, Component } from "react";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Material
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";
// import "./styles/contact.css";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class ForgotPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "",
        email: "",
        password: "",
        confirmPassword: "",
      },
      isAccessValid: false,
      errors: {
        passwordError: [],
      },
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const params = new URLSearchParams(location.search);
    const forgotPasswordToken = params.get("token") ?? null;
    const valid = this.checkToken(forgotPasswordToken);

    this.setState({
      isAccessValid: valid,
    });
  }

  handleChange = (prop) => (event) => {
    const { user } = this.state;
    if (event.target) {
      user[prop] = event.target.value;
    } else {
      user[prop] = event;
    }
    this.setState({ user: user });
  };

  showErrorMessage = (list) => {
    if (list.length === 0) return "";

    return list[0];

    let msg = "";

    msg = Object.values(list).join(", "); //.map( l => msg += l);

    return msg;
  };

  checkToken = (token) => {
    // return false if token is invalid
    if (token === null) return false;

    // do check token;
    return true;
  };

  render() {
    const { classes, location, match } = this.props;
    const { user, errors, isAccessValid } = this.state;
    return (
      <section>
        {/***/}
        <h1 className={"hero-head"}>Forgot Password</h1>
        <div className={"section-5 wf-section"} id="Buy-sec">
          <div className={"width-div white"}>
            <h2 className={"heading-2 black"}>Password Reset</h2>
            {isAccessValid && (
              <form className={"form-block w-form"}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <TextField
                        disabled={false}
                        label="User Name"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ className: classes.inputDetails }}
                        variant="outlined"
                        onChange={this.handleChange("username")}
                        value={user?.username ? user?.username : ""}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <TextField
                        disabled={false}
                        label="Email Address"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ className: classes.inputDetails }}
                        variant="outlined"
                        onChange={this.handleChange("mail")}
                        value={user?.mail ? user?.mail : ""}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <TextField
                        disabled={false}
                        label="Password"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ className: classes.inputDetails }}
                        variant="outlined"
                        onChange={this.handleChange("password")}
                        type="password"
                        value={user?.password ? user?.password : ""}
                        error={errors.passwordError.length > 0}
                        helperText={this.showErrorMessage(errors.passwordError)}
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <TextField
                        disabled={false}
                        label="Confirm Passowrd"
                        fullWidth
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ className: classes.inputDetails }}
                        variant="outlined"
                        onChange={this.handleChange("confirmPassword")}
                        type="password"
                        value={
                          user?.confirmPassword ? user?.confirmPassword : ""
                        }
                        error={errors.passwordError.length > 0}
                        helperText={this.showErrorMessage(errors.passwordError)}
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={6}>
                    <button
                      variant="contained"
                      className={"submit-button w-button"}
                    >
                      Submit
                    </button>
                  </Grid>
                </Grid>
              </form>
            )}
          </div>
        </div>
      </section>
    );
  }
}

ForgotPasswordPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const connectedForgotPasswordPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(ForgotPasswordPage))
);

export { connectedForgotPasswordPage as ForgotPasswordPage };
