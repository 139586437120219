import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

//Material
import {Grid} from '@material-ui/core';

const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },

});


class HowItWorks extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: "Test"
        }
    }

    handleChangebtn = (event) => {
        const value = event.currentTarget.value;
        this.setState({title: "test2"});
    }

    render() {
        const {classes} = this.props;

        return (
            <Grid container className={"section-container"} >
            
                    <Grid item xs={12}>
                        <h1 className={"heading"}>How it works</h1>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className={"how-to-block"}>
                        <div className={"div-block"}>
                            <img className={"image"} alt=""
                                src="Neuroplasticity%20Gym_files/600fa705a260b7ac2db85181_How1.png"
                                loading="lazy"
                                srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fa705a260b7ac2db85181_How1-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fa705a260b7ac2db85181_How1-p-800.png 800w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fa705a260b7ac2db85181_How1-p-1080.png 1080w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fa705a260b7ac2db85181_How1.png 1500w"
                                sizes="(max-width: 479px) 76vw, (max-width: 991px) 36vw, 26vw"/>
                            <h2 className={"number-over"}>1</h2></div>
                        <p className={"paragraph center-block"}>Select a plan that fits your goals +
                            buy.</p></Grid>
                    <Grid item xs={12} sm={6} md={4} className={"how-to-block"}>
                        <div className={"div-block"}>
                            <img className={"image"} alt=""
                                src="Neuroplasticity%20Gym_files/601849f4f2d7284741902aad_How2-2.png"
                                loading="lazy"
                                srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601849f4f2d7284741902aad_How2-2-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601849f4f2d7284741902aad_How2-2-p-800.png 800w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601849f4f2d7284741902aad_How2-2-p-1080.png 1080w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601849f4f2d7284741902aad_How2-2.png 1500w"
                                sizes="(max-width: 479px) 76vw, (max-width: 991px) 36vw, 26vw"/>
                            <h2 className={"number-over"}>2</h2></div>
                        <p className={"paragraph center-block"}>Get access to your dashboard.</p></Grid>
                    <Grid item xs={12} sm={6 }md={4} className={"how-to-block"}>
                        <div className={"div-block"}>
                            <img className={"image"} alt=""
                                src="Neuroplasticity%20Gym_files/600fd8971663231e51f12e92_How3.png"
                                loading="lazy"
                                srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fd8971663231e51f12e92_How3-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fd8971663231e51f12e92_How3-p-800.png 800w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fd8971663231e51f12e92_How3-p-1080.png 1080w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fd8971663231e51f12e92_How3.png 1500w"
                                sizes="(max-width: 479px) 76vw, (max-width: 991px) 36vw, 26vw"/>
                            <h2 className={"number-over"}>3</h2></div>
                        <p className={"paragraph center-block"}>Receive daily assignments.</p></Grid>
                    <Grid item xs={12} sm={6} md={4} className={"how-to-block"}>
                        <div className={"div-block"}>
                            <img className={"image"} alt=""
                                src="Neuroplasticity%20Gym_files/60184dc1ce5e0582e7d345a3_How4.png"
                                loading="lazy"
                                srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/60184dc1ce5e0582e7d345a3_How4-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/60184dc1ce5e0582e7d345a3_How4-p-800.png 800w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/60184dc1ce5e0582e7d345a3_How4-p-1080.png 1080w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/60184dc1ce5e0582e7d345a3_How4.png 1500w"
                                sizes="(max-width: 479px) 76vw, (max-width: 991px) 36vw, 26vw"/>
                            <h2 className={"number-over"}>4</h2></div>
                        <p className={"paragraph center-block"}>Complete daily assignments &amp;
                            check-in.</p></Grid>
                    <Grid item xs={12} sm={6} md={4} className={"how-to-block"}>
                        <div className={"div-block"}>
                            <img className={"image"} alt=""
                                src="Neuroplasticity%20Gym_files/601856b0ca2eb761e50e6265_How5.png"
                                loading="lazy"
                                srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601856b0ca2eb761e50e6265_How5-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601856b0ca2eb761e50e6265_How5-p-800.png 800w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601856b0ca2eb761e50e6265_How5-p-1080.png 1080w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/601856b0ca2eb761e50e6265_How5.png 1500w"
                                sizes="(max-width: 479px) 76vw, (max-width: 991px) 36vw, 26vw"/>
                            <h2 className={"number-over"}>5</h2></div>
                        <p className={"paragraph center-block"}>Increase your neuroplasticity score and grow
                            your sensory avatar.</p></Grid>
                    <Grid item xs={12} sm={6} md={4} className={"how-to-block"}>
                        <div className={"div-block"}>
                            <img className={"image"} alt=""
                                src="Neuroplasticity%20Gym_files/6018b11f87c38d3caa84f610_How6.png"
                                loading="lazy"
                                srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/6018b11f87c38d3caa84f610_How6-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/6018b11f87c38d3caa84f610_How6.png 1500w"
                                sizes="(max-width: 479px) 76vw, (max-width: 991px) 36vw, 26vw"/>
                            <h2 className={"number-over"}>6</h2></div>
                        <p className={"paragraph center-block"}>Unlock the next
                            program.</p>
                    </Grid>
            </Grid>
        );
    }
}

HowItWorks.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}

const connectedHowItWorks = withRouter(connect(mapStateToProps, null, null, {
    pure: false
})(withStyles(styles)(HowItWorks)));

export {connectedHowItWorks as HowItWorks};
