import * as actionTypes from "../../../../redux/actions/actionTypes";

export function doLoadingStart(loading = -1, message) {
    return {
    type: actionTypes.LOADING_START,
    loading,
    message
  }
}

export function doLoadingFinish (loading = -1){
  return {

    type: actionTypes.LOADING_FINISH,
    loading,
  }
}
