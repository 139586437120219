import { all, fork } from "redux-saga/effects";

import * as RegisterUserSaga from "../../component/registration/sagas/RegisterUserSaga";
import * as LoginUserSaga from "../../component/registration/sagas/LoginUserSaga";
import * as GetWorkoutCategoryByUserSaga from "../../component/dashboard/sagas/GetWorkoutCategoryByUserSaga";
import * as WorkoutCategoryCreateSaga from "../../component/dashboard/sagas/WorkoutCategoryCreateSaga";
import * as WorkoutCategoryStartSaga from "../../component/dashboard/sagas/WorkoutCategoryStartSaga";
import * as WorkoutCategoryUpdateSaga from "../../component/dashboard/sagas/WorkoutCategoryUpdateSaga";
import * as GetUserByMailSaga from "../../component/registration/sagas/GetUserByMailSaga";
import * as UserUpdateSaga from "../../component/registration/sagas/UserUpdateSaga";
import * as CreateCheckoutSessionSaga from "../../component/profile/sagas/CreateCheckoutSessionSaga";
import * as CreatePortalSessionSaga from "../../component/profile/sagas/CreatePortalSessionSaga";
import * as GetLeadersSaga from "../../component/dashboard/sagas/GetLeadersSaga";
import * as GetSubscriptionsSaga from "../../component/profile/sagas/GetSubscriptionsSaga";
import * as GetMembershipsSaga from "../../component/profile/sagas/GetMembershipsSaga";
import * as SendEmailSaga from "../../component/profile/sagas/SendEmailSaga";
import * as ChangePasswordSaga from "../../component/registration/sagas/ChangePasswordSaga";
import * as CompletePaymentSaga from "../../component/payment/sagas/CompletePaymentSaga";
import * as UnsubscribeSaga from "../../component/registration/sagas/UnsubscribeSaga";
import * as ContactEmailSaga from "../../component/contact/sagas/ContactEmailSaga";
export default function* rootSaga() {
  yield all(
    [
      ...Object.values(RegisterUserSaga),
      ...Object.values(LoginUserSaga),
      ...Object.values(GetWorkoutCategoryByUserSaga),
      ...Object.values(WorkoutCategoryCreateSaga),
      ...Object.values(WorkoutCategoryUpdateSaga),
      ...Object.values(GetUserByMailSaga),
      ...Object.values(UserUpdateSaga),
      ...Object.values(CreateCheckoutSessionSaga),
      ...Object.values(CreatePortalSessionSaga),
      ...Object.values(GetLeadersSaga),
      ...Object.values(GetSubscriptionsSaga),
      ...Object.values(GetMembershipsSaga),
      ...Object.values(SendEmailSaga),
      ...Object.values(ChangePasswordSaga),
      ...Object.values(CompletePaymentSaga),
      ...Object.values(UnsubscribeSaga),
        ...Object.values(WorkoutCategoryStartSaga),
        ...Object.values(ContactEmailSaga),
    ].map(fork)
  );
}
