import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import {
    showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

  
import GetUserByMailApi from "../../../communication/api/GetUserByMailApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("GetUserByMailApi",action);
  const mail = action.data.mail;

  const getUserByMailApi = new GetUserByMailApi(mail);
  try {

    const result = yield call(getUserByMailApi.getData);
    console.log("GetUserByMailApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("GetUserByMailApi",error);
    yield put(showApiError(error.toString()));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.GET_USER_BY_MAIL, handleSaga);
}

export default sagaWatcher;
