
import Axios from "./Axios";

class BaseApi {
  callApi(method, url, requestBody, headers) {
    throw new Error("callApi: method not implemented");
  }
}
export default BaseApi;

let api = null;

export function getApiImplementation() {
  const axiosInstance = new Axios("");
  setApiImplementation(axiosInstance);
  return api;
}

export function setApiImplementation(apiObj) {
  if (!(apiObj instanceof BaseApi)) {
    throw new Error("setApi: invalid BaseApi instance");
  }
  api = apiObj;
}
