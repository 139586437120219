import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import {
    showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

  
import GetWorkoutCategoryByUserApi from "../../../communication/api/GetWorkoutCategoryByUserApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("GetWorkoutCategoryByUserApi",action);
  const mail = action.data.mail;

  const getWorkoutCategoryByUserApi = new GetWorkoutCategoryByUserApi(mail);
  try {

    const result = yield call(getWorkoutCategoryByUserApi.getData);
    console.log("GetWorkoutCategoryByUserApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("GetWorkoutCategoryByUserApi",error);
    yield put(showApiError(error.toString()));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.WORKOUT_BY_USER_MAIL, handleSaga);
}

export default sagaWatcher;
