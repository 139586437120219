//Routes
import STRINGS from "./Strings";

//components
import { HomePage } from "../component/home/home.page";
import { SignUpPage } from "../component/registration/signup.page";
import { DashboardPage } from "../component/dashboard/dashboard.page";
import { ProfilePage } from "../component/profile/profile.page";
import { PaymentSuccessPage } from "../component/profile/payment.success.page";
import { PaymentCancelPage } from "../component/profile/payment.cancel.page";
import { ContactPage } from "../component/contact/contact.page";
import { CoursePage } from "../component/course/course.page";

import { getBaseUri } from "../../src/constants/Config";
import { TermsPage } from "../component/tems/terms.page";
import { PolicyPage } from "../component/policy/policy.page";
import { ForgotPasswordPage } from "../component/forgot-password/forgot-password.page";
import { UnsubscribePage } from "../component/unsubscribe/unsubscribe.page";

const baseUri = getBaseUri();
const baseUriHome = "/";

export const routeAccess = [
  {
    key: "",
    path: baseUri + "/",
    name: "",
    link: baseUri + "/",
    component: HomePage,
    display: false,
    roles: [],
    isAdmin: false,
  },
  {
    key: "courses",
    path: baseUri + "/courses",
    link: baseUri + "/courses",
    name: "COURSES",
    component: CoursePage,
    display: true,
    roles: [],
    isAdmin: false,
  },
  {
    key: "contact",
    path: baseUri + "/contact",
    link: baseUri + "/contact",
    name: "CONTACT",
    component: ContactPage,
    display: true,
    roles: [],
    isAdmin: false,
  },
  {
    key: "profile",
    path: baseUri + "/profile",
    link: baseUri + "/profile",
    name: "PROFILE",
    component: ProfilePage,
    display: false,
    roles: [],
    isAdmin: true,
  },
  {
    key: "terms",
    path: baseUri + "/terms",
    link: baseUri + "/terms",
    name: "TERMS",
    component: TermsPage,
    display: true,
    roles: [],
    isAdmin: false,
  },
  {
    key: "policy",
    path: baseUri + "/policy",
    link: baseUri + "/policy",
    name: "POLICY",
    component: PolicyPage,
    display: true,
    roles: [],
    isAdmin: false,
  },
  {
    key: "signup",
    path: baseUri + "/signup",
    link: baseUri + "/signup",
    name: "Sign up",
    component: SignUpPage,
    display: false,
    roles: [],
    isAdmin: false,
  },
  {
    key: "forgot-password",
    path: baseUri + "/forgot-password",
    link: baseUri + "/forgot-password",
    name: "Forgot Passwor",
    component: ForgotPasswordPage,
    display: false,
    roles: [],
    isAdmin: false,
  },
  {
    key: "dashboard",
    path: baseUri + "/dashboard",
    link: baseUri + "/dashboard",
    name: "Dashboard",
    component: DashboardPage,
    display: false,
    roles: [],
    isAdmin: false,
  },
  {
    key: "payment-success",
    path: baseUri + "/payment-success",
    link: baseUri + "/payment-success",
    name: "Payment Success",
    component: PaymentSuccessPage,
    display: false,
    roles: [],
    isAdmin: false,
  },
  {
    key: "payment-cancel",
    path: baseUri + "/payment-cancel",
    link: baseUri + "/payment-cancel",
    name: "Cancel Payment",
    component: PaymentCancelPage,
    display: false,
    roles: [],
    isAdmin: false,
  },
  {
    key: "unsubscribe",
    path: baseUri + "/unsubscribe",
    link: baseUri + "/unsubscribe",
    name: "Unsubscribe",
    component: UnsubscribePage,
    display: false,
    roles: [],
    isAdmin: false,
  },
];
