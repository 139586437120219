import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import {
    showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

  
import GetSubscriptionsApi from "../../../communication/api/GetSubscriptionsApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("GetSubscriptionsApi",action);
 
  try {
    const getSubscriptionsApi = new GetSubscriptionsApi();
    const result = yield call(getSubscriptionsApi.getData);
    console.log("GetSubscriptionsApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("GetSubscriptionsApi",error);
    yield put(showApiError(error.toString()));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.GET_SUBSCRIPTIONS, handleSaga);
}

export default sagaWatcher;
