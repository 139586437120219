import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createLogger } from "redux-logger";
import { PRODUCTION_ENV } from "../constants/AppConstants";
import rootReducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import rootSaga from "./sagas";

import reducer from './reducers';
import thunk from 'redux-thunk';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["VaultReducer"],
};
const logger = createLogger({
  predicate: () => process.env.NODE_ENV !== PRODUCTION_ENV,
});

const pReducer = persistReducer(persistConfig, rootReducer);

const middleware = applyMiddleware(sagaMiddleware, thunk, logger);

const store = createStore(pReducer, middleware);
const persistor = persistStore(store);
export { persistor, store };

export default store;
sagaMiddleware.run(rootSaga);
