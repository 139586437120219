import React, { useState, Component } from 'react'
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit'

import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

//Material-ui
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

//Constants
import STRINGS from "../../../constants/Strings";

//config
import { config } from '../../../constants/Config';

const styles = (theme) => ({
  root: {

  }, containerTop: {
    marginTop: 50,
  },
  table: {
    minWidth: 650,
  },backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class ErrorApiIndicator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMsg:false,
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {error,type} = this.props;
    if(prevProps.error !== error && error !==''){
      this.setState({showMsg: true});
     
    }
  }

  handleClose = (event) => {
    this.setState({showMsg:false});
    sessionStorage.removeItem(STRINGS.APP_TOKEN);
    sessionStorage.removeItem(STRINGS.APP_USERNAME);
    this.goToHome();
  }

  goToHome=(event)=>{
    console.log("goToHome");
    const url = config.getBaseUri();
    window.location.href = url+"/";
  }

  render() {
    const { 
      error,
      type,
      classes 
    } = this.props;
    const { showMsg } = this.state;

    console.log("showApiError",showMsg,this.props);

    return (
      <section>
        <div>
          <Dialog
            open={showMsg}
            onClose={(event) => this.handleClose(event)}
            aria-labelledby="customized-dialog-title"
            classes={{ paper: classes.paper }}
          >
            <DialogTitle id="customized-dialog-title" onClose={(event) => this.handleClose(event)}>
              {"Error"}
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                {error}
              </Typography>
            </DialogContent>
            <DialogActions>
              {(type && type === "confirm") &&
                <>
                  <Button onClick={(event) => this.handleOk(event)} variant="contained" color="primary">
                    {(this.props.showYesNo) &&
                      "Yes"
                      ||
                      "Ok"
                    }
                  </Button>
                  {(this.props.enableCancel) &&
                    <>
                      <Button onClick={(event) => this.handleCancel(event)} variant="contained" color="primary">
                        {(this.props.showYesNo) &&
                          "No"
                          ||
                          "Cancel"
                        }

                      </Button>
                    </>
                    ||
                    <>
                      <Button onClick={(event) => this.handleClose(event)} variant="contained" color="primary">
                        {(this.props.showYesNo) &&
                          "No"
                          ||
                          "Cancel"
                        }
                      </Button>
                    </>
                  }
                </>
                ||
                <Button onClick={(event) => this.handleClose(event)} variant="contained" color="primary">
                  {(this.props.showYesNo) &&
                    "Yes"
                    ||
                    "Ok"
                  }
                </Button>
              }

            </DialogActions>
          </Dialog>
        </div>
      </section>
    );
  };
}
ErrorApiIndicator.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.string,
};
ErrorApiIndicator.defaultProps = {
  loading: [],
  message: null,
};

const mapStateToProps = (state) => ({
  error: state.ErrorApiIndicatorReducer.error,
});

const connectedErrorApiIndicator = withRouter(connect(mapStateToProps, null, null, {
  pure: false
})(withStyles(styles)(ErrorApiIndicator)));
export { connectedErrorApiIndicator as ErrorApiIndicator };