import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import qr from 'query-string';

//Material
import {Grid} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';


//Action
import {createCheckoutSession, createPortalSession, getMemberships, getSubscriptions} from "./actions/ProfileAction";

//Constants
import STRINGS from "../../constants/Strings";

import './styles/profile.css';
import {SUBSCRIPTION_TYPE} from "../../constants/AppConstants";

const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },

});


class MembershipTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showRegistration: false,
            subscriptions: [],
            showAll: false,
        }
    }

    componentDidMount() {
        this.getMemberships();
    }

    getMemberships() {
        const {doGetMemberships, user, subscriptionType} = this.props;
        const username = sessionStorage.getItem(STRINGS.APP_USERNAME);
        const params = {
            username: username
        }

        doGetMemberships(params, (results) => {
            console.log("getMemberships", results);
            if (results) {
                this.setState({subscriptions: results});
                if(subscriptionType){
                    const sub = results.find(item => item.subscription_type === subscriptionType);
                    this.handleSubscription(sub.api_id);
                }
            }
        });
    }

    handleChangebtn = (event) => {
        const value = event.currentTarget.value;
        this.setState({title: "test2"});
    }

    viewRegistration = (event) => {
        this.setState(
            {
                showRegistration: true
            }
        );
    }

    handleCloseModal = (event) => {
        this.setState(
            {
                showRegistration: false
            }
        );
    }

    handleMonthlySubscription = (event) => {
        const {doCreateCheckoutSession} = this.props;
        const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);
        const priceLookup = {
            lookup_key: "price_1KCTL3LUD9RLC5LaFWuUvY5B",
            userMail: mail
        }
        doCreateCheckoutSession({priceLookup: priceLookup}, (res) => {
            console.log("doCreateCheckoutSession", res);
            if (res && res.url) {
                //window.open(res.url);
                window.location.href = res.url;
            }
        });
    }

    handleYearlySubscription = (event) => {
        const {doCreateCheckoutSession} = this.props;
        const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);
        const priceLookup = {
            lookup_key: "price_1KCTL3LUD9RLC5La3K950Bed",
            userMail: mail
        }
        doCreateCheckoutSession({priceLookup: priceLookup}, (res) => {
            console.log("doCreateCheckoutSession", res);
            if (res && res.url) {
                //window.open(res.url);
                window.location.href = res.url;
            }
        });
    }


    handleBilling = (sessionId) => {
        const {doCreatePortalSession, dogetUserByMail, user} = this.props;

        let params = qr.parse(this.props.location.search);
        const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);
        const priceLookup = {
            sessionId: sessionId,
            userMail: mail
        }

        doCreatePortalSession({priceLookup: priceLookup}, (res) => {
            console.log("doCreatePortalSession", res);
            if (res && res.url) {
                window.location.href = res.url;
            }
        });
    }

    handleSubscription = (appId) => {
        console.log("handleSubscription",appId);
        const {doCreateCheckoutSession} = this.props;
        const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);
        const priceLookup = {
            lookup_key: appId,
            userMail: mail
        }
        doCreateCheckoutSession({priceLookup: priceLookup}, (res) => {
            console.log("doCreateCheckoutSession", res);
            if (res && res.url) {
                //window.open(res.url);
                window.location.href = res.url;
            }
        });
    }

    showAll = () => {
        this.setState({showAll:true})
    }

    render() {
        const {classes, user, subscriptionType} = this.props;
        const {
            subscriptions,
            showAll,
        } = this.state;

        console.log("subscriptions", user, subscriptions);

        return (
            <section>
                {/***/}
                <Container maxWidth="md">
                    <Typography variant="h6">Manage Membership</Typography><br/>
                    <Grid container spacing={1}>
                        {subscriptions.map((raw) => (
                            <>
                                {console.log(raw)}
                                {((raw?.subscription_type === SUBSCRIPTION_TYPE.MONTHLY || raw?.subscription_type === SUBSCRIPTION_TYPE.GROUP || raw?.subscription_type === SUBSCRIPTION_TYPE.YEARLY) &&
                                    ((subscriptionType && (subscriptionType === raw?.subscription_type ) || showAll) || (!subscriptionType) ))  && (
                                    <>
                                        <Grid item xs={12} md={6}>
                                            {raw.product_name} ${raw.price} CAD
                                        </Grid>
                                        {raw?.subscription_item?.plan && raw.subscription_item.plan.active === true &&
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <Button variant="contained"
                                                            onClick={() => this.handleBilling(raw.sessionId)}>Manage</Button>
                                                </Grid>
                                            </>
                                            ||
                                            <>
                                                <Grid item xs={12} md={6}>
                                                    <Button variant="contained"
                                                            onClick={() => this.handleSubscription(raw.api_id)}>Subscribe</Button>
                                                </Grid>
                                            </>
                                        }
                                    </>
                                )}
                            </>
                        ))}
                        {subscriptionType && (
                            <Grid item xs={12}>
                                <hr/>
                                <Button variant="contained"
                                        onClick={() => this.showAll()}>Show all subscription type</Button>
                            </Grid>
                        )}
                    </Grid>
                </Container>
            </section>
        );
    }
}

MembershipTab.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}


const mapDispatchToProps = (dispatch) => {
    return {
        doCreateCheckoutSession: (params, callback) => dispatch(createCheckoutSession(params, callback)),
        doCreatePortalSession: (params, callback) => dispatch(createPortalSession(params, callback)),
        doGetSubscriptions: (callback) => dispatch(getSubscriptions(callback)),
        doGetMemberships: (params, callback) => dispatch(getMemberships(params, callback)),

    }
}

const connectedMembershipTab = withRouter(connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false
})(withStyles(styles)(MembershipTab)));

export {connectedMembershipTab as MembershipTab};
