import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import {
  showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

import LoginUserApi from "../../../communication/api/LoginUserApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';
import axios, { post } from 'axios';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("LoginUserApi",action);
  const user = action.data.user;

  const loginUserApi = new LoginUserApi(user);
  try {

    const result = yield call(loginUserApi.getData);

    console.log("LoginUserApi",result);

     yield call(action.callback, result.data);

  } catch (error) {
    console.log("LoginUserApi",error);
    yield put(showApiError("Invalid user name or password"));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.LOGIN_USER, handleSaga);
}

export default sagaWatcher;
