import React, { useState, Component } from "react";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import theme from "../../theme";

import Box from "@material-ui/core/Box";
import { Grid } from "@material-ui/core";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

//component
import { RegisterModal } from "../registration/register.modal";

//Constants
import STRINGS from "../../constants/Strings";
import { routeAccess } from "../../constants/Routes";
import { config } from "../../constants/Config";

import { getBaseUri } from "../../../src/constants/Config";

const baseUri = getBaseUri();

const styles = (theme) => ({
  root: {
    position: "fixed",
    left: 0,
    bottom: 0,
    width: "100%",
    backgroundColor: "#000",
    color: "white",
    textAlign: "center",
  },
  containerTop: {
    marginTop: 50,
  },
  labelVersion: {
    color: "#fff",
    marginRight: -20,
  },
  labelTitle: {
    height: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "10px",
  },
  menuList: {
    listStyleType: "none",
    padding: 0,
    margin: 0,
    fontSize: "15px",
  },
});

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: "",
      disableMenu: true,
      showRegisterModal: false,
      isLoggedIn: false,
    };
  }

  componentDidMount() {
    this.isLoggedIn();
  }
  componentDidUpdate(prevProps) {}

  handleOpenRegisterModal = (event) => {
    const { changeId } = this.state.ecab;
    this.setState({ showRegisterModal: true });
  };

  handleCloseRegisterModal = (event) => {
    this.setState({ showRegisterModal: false });
  };

  logOut = (event) => {
    sessionStorage.removeItem(STRINGS.APP_TOKEN);
    sessionStorage.removeItem(STRINGS.APP_USERNAME);
    this.goToHome();
  };

  goToHome = (event) => {
    console.log("goToHome");
    const url = config.getBaseUri();
    window.location.href = url + "/";
  };

  isLoggedIn = () => {
    const { isLoggedIn } = this.state;
    console.log("isLoggedIn", isLoggedIn);

    const token = sessionStorage.getItem(STRINGS.APP_TOKEN);
    if (token && token !== "" && !isLoggedIn) {
      this.setState({
        isLoggedIn: true,
      });
    }
    console.log("isLoggedIn");
  };

  render() {
    const { classes } = this.props;
    const { isLoggedIn } = this.state;

    console.log("isLoggedIn", isLoggedIn);
    return (
      <>
        <section>
          {!isLoggedIn && (
            <>
              <div className="static-nav">
                <div
                  className="lottie-animation"
                  data-w-id="df1e8cf5-260a-ed24-bf51-74df54564b4f"
                  data-duration="0"
                  data-default-duration="3"
                  data-renderer="svg"
                  data-is-ix2-target="0"
                  data-autoplay="1"
                  data-direction="1"
                  data-loop="0"
                  data-src="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fea5a5c33d475286e0da2_lf30_editor_njo5oqnh.json"
                  data-animation-type="lottie"
                ></div>
                <a className={"button w-button"} href={baseUri + "/signup"}>
                  Sign up
                </a>
              </div>
            </>
          )}
          <Container className={classes.containerTop}>
            <div className={"footer"}>
              <Grid
                className={" "}
                container
                direction="row"
                alignItems="center"
              >
                <Grid item xs={12} md={6} sm={6}>
                  <div className={"div-block-2"}>
                    {routeAccess.map((route, index) => (
                      <>
                        {(route.display === true && (
                          <>
                            <Link
                              href={route.path}
                              underline="none"
                              className={"link-thin"}
                              onClick={() => this.handleSelected}
                              key={index}
                            >
                              {route.name}
                            </Link>
                          </>
                        )) ||
                          (route.isAdmin === true && isLoggedIn === true && (
                            <>
                              <Link
                                href={route.path}
                                underline="none"
                                className={"link-thin"}
                                onClick={() => this.handleSelected}
                                key={index}
                              >
                                {route.name}
                              </Link>
                            </>
                          ))}
                      </>
                    ))}

                    {isLoggedIn && (
                      <a
                        href={"#"}
                        underline="none"
                        className={"link-thin"}
                        onClick={this.logOut}
                      >
                        Logout
                      </a>
                    )}
                  </div>
                </Grid>
                <Grid item xs={12} md={6} sm={6} className={""}>
                  <div class="div-block">
                    <img
                      className="image-4"
                      id="w-node-_9b94a3af-25ac-87ce-d336-b43ddcb39672-dcb39668"
                      alt=""
                      src={
                        process.env.PUBLIC_URL +
                        "resources/600fdb0b3d17f38dd2e711dd_Chill.png"
                      }
                      loading="lazy"
                      srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill-p-800.png 800w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill-p-1080.png 1080w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill.png 1536w"
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 62vw, 600px"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* <Grid container spacing={3} alignItems="center" justifyContent='center' >
              <Grid item xs={6} alignItems="center" justifyContent='center' > 
                <div className={"footer wf-section"} data-w-id="9b94a3af-25ac-87ce-d336-b43ddcb39668">
                    <div className={"div-block-2"} id="w-node-_9b94a3af-25ac-87ce-d336-b43ddcb39669-dcb39668">
                      {routeAccess.map((route, index) => (
                        <>
                          {(route.display === true) &&
                            <>
                              <Link 
                                href={route.path} 
                                underline="none"
                                className={"link-thin"}
                                onClick={() => this.handleSelected}
                                key={index}
                              >
                                {route.name}
                              </Link>
                            </>
                            || (route.isAdmin === true && isLoggedIn ===true) &&
                            <>
                              <Link
                                href={route.path}
                                underline="none"
                                className={"link-thin"}
                                onClick={() => this.handleSelected}
                                key={index}
                              >
                                {route.name}
                              </Link>
                            </>
                          }
                         
                        </>
                      ))}
                      
                    </div>
                  </div> 
              </Grid>
              <Grid item xs={6} className={"footer"}> 
                  <div class="div-block">
                    <img
                      className="image-4" id="w-node-_9b94a3af-25ac-87ce-d336-b43ddcb39672-dcb39668" alt=""
                      src={process.env.PUBLIC_URL + "resources/600fdb0b3d17f38dd2e711dd_Chill.png"} loading="lazy"
                      srcSet="https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill-p-500.png 500w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill-p-800.png 800w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill-p-1080.png 1080w, https://uploads-ssl.webflow.com/5fc586d9dd2ecfcfd479e2aa/600fdb0b3d17f38dd2e711dd_Chill.png 1536w"
                      sizes="(max-width: 479px) 93vw, (max-width: 991px) 62vw, 600px"
                    />
                  </div> 
              </Grid>
            </Grid> */}
          </Container>
        </section>
      </>
    );
  }
}

FooterBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedFooterBar = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(FooterBar))
);
export { connectedFooterBar as FooterBar };
