import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class WorkoutCategoryCreateApi extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {};
  url = BE_NEURO_URL+"workoutCategory/create";

  constructor(workout) {
    super();
    this.body=workout;
    console.log("WorkoutCategoryCreateApi-",this.url,workout);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
