import React, { useState,Component } from 'react'
import {
    createSlice,
    createAsyncThunk,
    createSelector,
    createEntityAdapter,
  } from '@reduxjs/toolkit'

import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';  

//Material
import { Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';

//Components
import {ProfileTab} from "./profile.tab";
import {MembershipTab} from "./membership.tab";
import {DialogMessage} from "../dialog-message/dialog.message";

//Action
import {
    getUserByMail,
    updateUser
} from "../registration/actions/UserAction";
  
//Constants
import STRINGS from "../../constants/Strings";
import { config } from '../../constants/Config';

import './styles/profile.css';

const styles = (theme) => ({
    root: {
       
    },
    containerTop:{
      marginTop:50,
    },
    
});

  
class PaymentCancelPage extends Component {
    
    constructor(props) {
      super(props);
      this.state = {
        showRegistration: false,
        tab:{
            profile: true,
            membership: false
        },
        user:{},
        msgbox: {
            showMsg: false,
            msgTitle: '',
            msgBody: ''
          },
      }
    }

    componentDidMount() {
        
    }

    

    handleCloseDM = (event) => {
        this.setState({
            msgbox: {
                showMsg: false,
                msgTitle: '',
                msgBody: ''
            },
        });
    };

    render() {
      const { classes } = this.props;
      const {
        tab,
        user
      } = this.state;

      return (
          <section>
              {/***/}
              <Container className={"profile-background"} maxWidth="md">
                  <Typography variant="h6">
                  Checkout canceled
                   </Typography>
                   <br />
                  <Paper style={{padding:10}}>
                      <Grid container spacing={1}>
                          <Grid item xs={12}>
                                Picked the wrong subscription? Shop around then come back to pay!
                          </Grid>
                      </Grid>
                  </Paper>
                  <DialogMessage
                    showMsg={this.state.msgbox.showMsg}
                    msgTitle={this.state.msgbox.msgTitle}
                    msgBody={this.state.msgbox.msgBody}
                    handleClose={this.handleCloseDM}
                    name={this.state.msgbox.name}
                    type={this.state.msgbox.type}
                    data={this.state.msgbox.data}
                    handleOk={this.handleOkDialogMessage}
                    ></DialogMessage>
              </Container>
          </section>
          
      );
    }
}

PaymentCancelPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) =>{
    return {
      dogetUserByMail: (params,callback) => dispatch(getUserByMail(params,callback)),
      doupdateUser: (params,callback) => dispatch(updateUser(params,callback)),
    }
  }

const connectedPaymentCancelPage = withRouter(connect(mapStateToProps, mapDispatchToProps, null, {
  pure: false
})(withStyles(styles)(PaymentCancelPage)));

export { connectedPaymentCancelPage as PaymentCancelPage };
