import React, {Component} from "react";

import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

//Material
import {withStyles} from "@material-ui/core/styles";
import {Grid} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import Typography from "@material-ui/core/Typography";

//Icon
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

//component
import {DialogMessage} from "../dialog-message/dialog.message";

//validators
import checkErrors from "../../Utils/InputValidator";
import VALIDATIONS from "../../constants/ValidationList";

//Action
import {login, register} from "./actions/UserAction";
import {sendEmail} from "../profile/actions/ProfileAction";

//Constants
import STRINGS from "../../constants/Strings";
import MODALMESSAGES from "../../constants/Messages";

//config
import {config} from "../../constants/Config";
import {HTTP_STATUS} from "../../constants/AppConstants";

const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },
    table: {
        minWidth: 650,
        marginTop: 20,
    },
    buttonSubmit: {
        width: 200,
    },
    paper: {
        position: "absolute",
        maxWidth: "65%",
        minWidth: "40%",
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #grey",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(1),
    },
    duration: {
        border: "1px solid grey",
        height: 50,
        marginBottom: 10,
        padding: 10,
    },
    updateBorder: {
        border: "1px solid grey",
        minHeight: 200,
    },
    meetingChip: {
        marginLeft: 5,
    },
    meetingBox: {
        height: 20,
    },
    labelRow: {
        display: "flex",
        justifyContent: "flex-end",
        alignrecipients: "center",
        height: 20,
        fontSize: 14,
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CREATE_USER = "CREATE_USER";

class RegisterModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recipients: [],
            value: "",
            isFormValid: false,
            errors: {
                usernameError: [],
                emailError: [],
                passwordError: [],
            },
            user: {
                mail: "",
                username: "",
                password: "",
            },
            msgbox: {
                showMsg: false,
                msgTitle: "",
                msgBody: "",
            },
        };
    }

    componentDidMount() {
    }

    handleCloseModal = (event) => {
        if (this.props.handleCloseModal) {
            this.props.handleCloseModal(event);
        }
    };

    handleChange = (prop) => (event) => {
        const {user} = this.state;
        if (event.target) {
            user[prop] = event.target.value;
        } else {
            user[prop] = event;
        }
        this.setState({user: user});
    };

    onSubmitCreate = (event) => {
        if (!this.verifyData()) return;

        let isUserExist = false;
        // check if user exist then show modal
        if (isUserExist) return this.showExistingUserModal();

        this.register();
        // this.setState({
        //   msgbox: {
        //     name: CREATE_USER,
        //     type: "confirm",
        //     showMsg: true,
        //     msgTitle: "Confirm",
        //     msgBody: STRINGS.REGISTRATION.CONFIRM_CREATE,
        //   },
        // });
    };

    verifyData = () => {
        let formIsValid = true;
        const {user, errors} = this.state;

        const emailRes = checkErrors(user?.mail, VALIDATIONS.REGISTRATION.mail);
        const usernameRes = checkErrors(
            user?.username,
            VALIDATIONS.REGISTRATION.username
        );
        const passwordRes = checkErrors(
            user?.password,
            VALIDATIONS.REGISTRATION.password
        );

        if (
            emailRes.length > 0 ||
            usernameRes.length > 0 ||
            passwordRes.length > 0
        ) {
            formIsValid = false;
        }

        this.setState({
            errors: {
                ...errors,
                emailError: emailRes,
                usernameError: usernameRes,
                passwordError: passwordRes,
            },
            isFormValid: formIsValid,
        });

        return formIsValid;
    };

    showErrorMessage = (list) => {
        if (list.length === 0) return "";

        return list[0];

        let msg = "";

        msg = Object.values(list).join(", "); //.map( l => msg += l);

        return msg;
    };

    clearRegistrationData = () => {
        this.setState({
            user: {
                mail: "",
                username: "",
                password: "",
            },
        });
    };

    handleOkDialogMessage = (result, name) => {
        if (result && name === CREATE_USER) {
            this.setState({
                inputErrors: {},
                msgbox: {
                    showMsg: false,
                    msgTitle: "",
                    msgBody: "",
                },
            });
            this.register();
        } else {
            this.setState({
                msgbox: {
                    showMsg: false,
                    msgTitle: "",
                    msgBody: "",
                },
            });
        }
    };

    showExistingUserModal = () => {
        this.setState({
            msgbox: {
                showMsg: true,
                msgTitle: "User Exist",
                msgBody: MODALMESSAGES.REGISTRATION.USEREXIST,
            },
        });
    };

    register() {
        const {doRegister, history} = this.props;
        const {user} = this.state;
        console.log("register", user);

        doRegister({user: user}, (result) => {
            console.log("doRegister", result);
            if (result.code === HTTP_STATUS.CREATED) {
                this.props.handleCloseModal();
                this.sendEmail(user);
                this.clearRegistrationData();
                this.login(user);
            } else {
                this.setState({
                    msgbox: {
                        showMsg: true,
                        msgTitle: "Error",
                        msgBody: result.message,
                    },
                });
            }
        });
    }

    login(user) {
        const {doLogin, subscriptionType} = this.props;

        console.log("login", user);
        user.mail = user.username;

        doLogin({user: user}, (result) => {
            console.log("doLogin", result);
            if (result) {
                const token = result.access_token ? result.access_token : "";
                if (token !== "") {
                    sessionStorage.setItem(STRINGS.APP_TOKEN, token);
                    sessionStorage.setItem(STRINGS.APP_USERNAME, user.username);
                    this.handleCloseModal("");

                    const url = config.getBaseUri();
                    console.log("url", url);
                    window.location.href = url + "/profile?register=" + subscriptionType;
                    // this.props.history.push({
                    //   pathname: url + "/dashboard",
                    // });
                } else {
                    this.setState({
                        msgbox: {
                            showMsg: true,
                            msgTitle: "Error",
                            msgBody: STRINGS.LOGIN.ERROR,
                        },
                    });
                }
            }
        });
    }

    sendEmail(user) {
        const {doSendEmail} = this.props;
        const email = {
            bcc: "",
            body: "",
            cc: "",
            content: "",
            from: "",
            model: {
                firstname: user.username,
            },
            subject: "[Neuroplasticity Gym] Registration",
            template: "welcome",
            to: user.mail,
        };

        doSendEmail({email: email}, (result) => {
            console.log("doSendEmail", result);
            if (result) {
                this.setState({
                    msgbox: {
                        showMsg: true,
                        msgTitle: "",
                        msgBody: result.message,
                    },
                });
                this.props.handleCloseModal();
            }
        });
    }

    handleCloseDM = (event) => {
        this.setState({
            msgbox: {
                showMsg: false,
                msgTitle: "",
                msgBody: "",
            },
        });
    };

    render() {
        const {classes, recipients, showRegistration} = this.props;

        const {user, errors} = this.state;

        let meetingRecipients = [];
        if (this.state.recipients.length === 0) {
            meetingRecipients = recipients;
        } else {
            meetingRecipients = this.state.recipients;
        }

        return (
            <section>
                <Dialog
                    open={showRegistration}
                    onClose={(event) => this.handleCloseModal(event)}
                    aria-labelledby="customized-dialog-title"
                    scroll="paper"
                    TransitionComponent={Transition}
                    classes={{paper: classes.paper}}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={1}></Grid>
                        <Grid item xs={10}>
                            <Box display="flex" justifyContent="center">
                                <Typography gutterBottom variant="h6">
                                    Create Account
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1}>
                            <Box display="flex" justifyContent="flex-end">
                                <IconButton
                                    color="primary"
                                    ria-label="upload picture"
                                    component="span"
                                    onClick={(event) => this.handleCloseModal(event)}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Box>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <TextField
                                        disabled={false}
                                        label="User Name"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{className: classes.inputDetails}}
                                        variant="outlined"
                                        onChange={this.handleChange("username")}
                                        value={user?.username ? user?.username : ""}
                                        error={errors.usernameError.length > 0}
                                        helperText={this.showErrorMessage(errors.usernameError)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <TextField
                                        disabled={false}
                                        label="Email Address"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{className: classes.inputDetails}}
                                        variant="outlined"
                                        onChange={this.handleChange("mail")}
                                        value={user?.mail ? user?.mail : ""}
                                        error={errors.emailError.length > 0}
                                        helperText={this.showErrorMessage(errors.emailError)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center">
                                    <TextField
                                        disabled={false}
                                        label="Password"
                                        fullWidth
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{className: classes.inputDetails}}
                                        variant="outlined"
                                        onChange={this.handleChange("password")}
                                        type="password"
                                        value={user?.password ? user?.password : ""}
                                        error={errors.passwordError.length > 0}
                                        helperText={this.showErrorMessage(errors.passwordError)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={(event) => this.onSubmitCreate(event)}
                            variant="contained"
                            color="primary"
                        >
                            Submit
                        </Button>
                        <Button
                            onClick={(event) => this.handleCloseModal(event)}
                            variant="contained"
                            color="primary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <DialogMessage
                    showMsg={this.state.msgbox.showMsg}
                    msgTitle={this.state.msgbox.msgTitle}
                    msgBody={this.state.msgbox.msgBody}
                    handleClose={this.handleCloseDM}
                    name={this.state.msgbox.name}
                    type={this.state.msgbox.type}
                    data={this.state.msgbox.data}
                    handleOk={this.handleOkDialogMessage}
                ></DialogMessage>
            </section>
        );
    }
}

RegisterModal.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        doRegister: (params, callback) => dispatch(register(params, callback)),
        doSendEmail: (params, callback) => dispatch(sendEmail(params, callback)),
        doLogin: (params, callback) => dispatch(login(params, callback)),
    };
};
const connectedRegisterModal = withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, {
        pure: false,
    })(withStyles(styles)(RegisterModal))
);

export {connectedRegisterModal as RegisterModal};
