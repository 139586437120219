import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "@material-ui/core/Modal";
import { withRouter } from "react-router";
import AppConstants from "../../../constants/AppConstants";
import STRINGS from "../../../constants/Strings";

class PopupModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.showModal,
      showLoading: true,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  handleClose = () => {
    this.setState({ show: false });
  };

  onIframeLoad = () => {
    setInterval(() => {
      this.setState({ showLoading: false });
    }, 500);
  };

  render() {
    const { modalType } = this.props;
    const popUpHeaderClassNames = ["popup-icon"];
    if (modalType === AppConstants.MODAL_TYPE.SUCCESS) {
      popUpHeaderClassNames.push("popup-modal-success-icon");
    } else if (modalType === AppConstants.MODAL_TYPE.FAILURE) {
      popUpHeaderClassNames.push("popup-modal-failure-icon");
    } else if (modalType === AppConstants.MODAL_TYPE.INFORMATION) {
      popUpHeaderClassNames.push("popup-modal-info-icon");
    }

    const {
      title,
      description,
      description2,
      type,
      toggleModal,
      popupBtnClick,
      btnText,
      showPopupModalButtons,
      iframeModal,
      iframeUrl,
      footer,
      closeBtnClick,
      subtitle,
      addToVault,
      tag,
      seeDetailsClick,
      shortDescription,
    } = this.props;

    const { show, showLoading } = this.state;

    return (
      <Modal
        open={show}
        onClose={() => {
          this.handleClose();
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={"modalBox"}>
          <h2 id="simple-modal-title">{title}</h2>
          <p id="simple-modal-description">{description}</p>
        </div>
      </Modal>
    );
  }
}

PopupModal.propTypes = {
  showModal: PropTypes.bool,
  type: PropTypes.string,
  modalType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  btnText: PropTypes.string,
  toggleModal: PropTypes.func,
  popupBtnClick: PropTypes.func,
  closeBtnClick: PropTypes.func,
  showPopupModalButtons: PropTypes.bool,
  iframeModal: PropTypes.bool,
  iframeUrl: PropTypes.string,
  footer: PropTypes.string,
  subtitle: PropTypes.string,
  addToVault: PropTypes.bool,
  tag: PropTypes.bool,
  seeDetailsClick: PropTypes.bool,
  shortDescription: PropTypes.string,
};

PopupModal.defaultProps = {
  showModal: false,
  type: "success",
  modalType: "",
  title: "Test title",
  description: "Test description",
  btnText: "OK",
  toggleModal: () => {},
  popupBtnClick: () => {},
  closeBtnClick: () => {},
  showPopupModalButtons: true,
  iframeModal: false,
  iframeUrl: "",
  footer: "",
  subtitle: "",
  addToVault: true,
  tag: false,
  seeDetailsClick: false,
  shortDescription: "",
};

export default withRouter(PopupModal);
