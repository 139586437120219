import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";
import WorkoutCategoryStartApi from "../../../communication/api/WorkoutCategoryStartApi";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("WorkoutCategoryStartApi",action);
  const workout = action.data.workout;

  const workoutCategoryStartApi = new WorkoutCategoryStartApi(workout);
  try {

    const result = yield call(workoutCategoryStartApi.getData);
    console.log("WorkoutCategoryStartApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("WorkoutCategoryStartApi",error);
   // yield put(showApiError(error));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.WORKOUT_START, handleSaga);
}

export default sagaWatcher;
