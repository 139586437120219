import React, {Component} from "react";

import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";

//Material
import {Grid} from "@material-ui/core";
import LockIcon from "@mui/icons-material/Lock";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

import ButtonGroup from "@material-ui/core/ButtonGroup";

//Action
import {createWorkout, getWorkoutByUserMail, updateWorkout,} from "./actions/WorkoutAction";

import {createCheckoutSession, createPortalSession,} from "../profile/actions/ProfileAction";

import {getUserByMail} from "../registration/actions/UserAction";

//Constants

import Moment from "moment";

import "./styles/dashboard.css";
import CONSTANT from "../../constants/AppConstants";
import {DialogMessage} from "../dialog-message/dialog.message";
import moment from "moment";

const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },
});

class Workout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            user: {},
            counter: {
                focus: 0,
                streak: 0,
                time: 0,
            },
            hasWorkout: false,
            control: {
                streak: 0,
                focus: 0,
                time: 0,
            },
            subscription: {},
            masterName: "",
        };
    }

    componentDidMount() {
        const {workout} = this.props;
        console.log("componentDidMount",workout);
        this.updateControl(workout);
    }

    componentDidUpdate(prevProps) {
        const {workout} = this.props;
        if (prevProps.workout != workout) {
            console.log("componentDidUpdate",workout);
            this.updateControl(workout);
        }

    }

    updateControl = (workout) => {
        if(workout.status === CONSTANT.STATUS.ACTIVE){
            this.setState({
                control: {
                    streak: workout.streak,
                    focus: 0,
                    time: 0,
                }
            });
        }else{
            this.setState({
                control: {
                    streak: workout.streak,
                    focus: 0,
                    time: 0,
                }
            });
        }
    }

    handleFocusIncrement = (event) => {
        console.log("handleFocusIncrement",event);
        const {control} = this.state;
        const {workout} = this.props;
        //counter["focus"] = counter["focus"] + 1;
        //this.setState({counter:counter});
        if(control["focus"] < 1){
            control["focus"] = control["focus"] + 1;
        }

    };

    handleFocusDecrement = (event) => {
        const {setWorkout, workout} = this.props;
        const {control} = this.state;

        if (control["focus"] > -1) {
            control["focus"] = control["focus"] - 1;
        }
    };

    handleTimeIncrement = (event) => {
        const {workout} = this.props;
        const {control} = this.state;

        if (control["time"] === 60) {
            control["time"] = 60;
        } else {
            control["time"] = control["time"] + 5;
        }

    };

    handleTimeDecrement = (event) => {
        const {workout} = this.props;
        const {control} = this.state;

        if (control["time"] > 0) {
            control["time"] = control["time"] - 5;
        } else {
            control["time"] = 0;
        }

    };

    handleSubmit = (event) => {
        const {
            doCreateWorkout,
            doUpdateWorkout,
            doGetUserByMail,
            workout,
            setWorkout,
            refresh,
        } = this.props;
        const {control} = this.state;

        const params = {
            userId: workout.userId,
            streak: 1,
            focus: control.focus,
            time: control.time,
            id: workout.id,
        };
        doUpdateWorkout({workout: params}, (res) => {
            console.log("handleSubmit", params, res);
            console.log("handleSubmit", workout);
            if (res && res.code === 200) {
                this.toggleModal();
                refresh();
            }
        });
    };

    verifySubscription(subscriptions) {
        const {name, isSecond} = this.props;
        const {masterName} = this.state;
        const currentDate = Moment();
        subscriptions.forEach((raw) => {
            const isWorkoutSecond = isSecond;
            if (
                raw.product_name.toLowerCase() === name.toLowerCase() ||
                raw.product_name.toLowerCase() === masterName.toLowerCase() ||
                isWorkoutSecond
            ) {
                const subscriptionDate = Moment.unix(raw?.subscription_item?.created);
                const diff = currentDate.diff(subscriptionDate, "months", true);
                console.log(
                    "verifySubscription",
                    name,
                    subscriptionDate,
                    masterName,
                    currentDate,
                    isWorkoutSecond
                );

                let isActive = false;
                if (raw?.subscription_item?.plan?.active === true && !isWorkoutSecond) {
                    console.log("verifySubscription", raw);
                    isActive = true;
                }

                if (diff >= 1) isActive = true;

                this.setState({isEnabled: isActive, subscription: raw});
            }
        });
    }

    handleSubscription = () => {
        const {history} = this.props;
        history.push({
            pathname: "/profile",
            search: '?tab=membership'
        });
    };

    toggleModal = () => {
        console.log("toggleModal");
        const {showModal} = this.state;
        this.setState({showModal: !showModal});
    }

    userCanUpdate = (userScoreUpdate) =>{
        let result = true;
        if(userScoreUpdate){
            const {workout } = this.props;
            const scoreLastUpdated = moment.utc(moment.utc(moment(userScoreUpdate)).format());
            const today = moment.utc(moment.utc(moment(new Date())).format());
            console.log("today date", today);
            console.log("last update",scoreLastUpdated);
            console.log("today",today.hour(),scoreLastUpdated.hour());
            if(scoreLastUpdated.year() === today.year()){
                const dayOfYearToday = parseInt(today.format("DDD"));
                const dayOfYearUpdate = parseInt(scoreLastUpdated.format("DDD"));
                console.log("day of year",dayOfYearToday,dayOfYearUpdate);
                if(scoreLastUpdated.dayOfYear() < today.dayOfYear()){
                    result = true;
                }else{
                    console.log("hour",today.hour(),scoreLastUpdated.hour());
                    if(scoreLastUpdated.hour() < today.hour()){
                        result = true;
                    }else {
                        result = false;
                    }
                }
            }else if(scoreLastUpdated.year() > today.year()){
                result = false;
            } else{
                result = true;
            }
        }
        return result;
    }

    render() {
        const {classes, user, name, subscriptions, isSecond} =
            this.props;

        const {control, subscription, showModal} = this.state;

        const {workout, startProgram} = this.props;

        const counter = {
            focus: control.focus ? control.focus : 0,
            streak: control.streak ? control.streak : 0,
            time: control.time ? control.time : 0,
        };

        return (
            <section>
                <DialogMessage
                    showMsg={showModal}
                    msgTitle={"Tracking updated"}
                    msgBody={"Focus and time recorded."}
                    handleClose={this.toggleModal}
                    name={"name"}
                    type={"type"}
                    data={"data"}
                    handleOk={this.toggleModal}/>
                <div
                    className={"slide w-slide"}
                    aria-label="3 of 3"
                    role="group"
                    aria-hidden="true"
                >
                    <div class="dashboard-div" aria-hidden="true">
                        <img
                            src={
                                process.env.PUBLIC_URL +
                                CONSTANT.IMAGES[workout.workout.id]
                            }
                            loading="lazy"
                            alt=""
                            className={"image-11 " + (workout.status !== CONSTANT.STATUS.ACTIVE && "image-lock")}
                            aria-hidden="true"
                        />
                        {workout.status === CONSTANT.STATUS.LOCKED || (workout.status === CONSTANT.STATUS.ACTIVE && !this.userCanUpdate(workout.userScoreUpdate)) && <LockIcon/>}
                        <h2 className={"heading-2 center moved"} aria-hidden="true">
                            {workout.workout.name}
                        </h2>
                        {workout.status === CONSTANT.STATUS.ACTIVE && (
                            <p className={"paragraph center-block"} aria-hidden="true">
                                This is your dashboard. Track you streak, focus levels and total
                                time training.
                            </p>
                        )}
                        {workout.status === CONSTANT.STATUS.INACTIVE && (
                            <p className={"paragraph center-block"} aria-hidden="true">
                                Click on start button to start your program.
                            </p>
                        )}
                        {workout.status === CONSTANT.STATUS.LOCKED && (
                            <p className={"paragraph center-block"} aria-hidden="true">
                                This program is locked. It would be unlocked once you complete the required programs.
                            </p>
                        )}

                        <div className={"metrics-containter"} aria-hidden="true">
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <div className={"streak-div"} aria-hidden="true">
                                        <div
                                            className={"parallax-number-container"}
                                            aria-hidden="true"
                                        >
                                            <h3 className={"heading-special"} aria-hidden="true">
                                                {workout.streak}
                                            </h3>
                                            <h3
                                                className={"heading-special-hollow"}
                                                aria-hidden="true"
                                            >
                                                {workout.streak}
                                            </h3>
                                        </div>
                                        <div aria-hidden="true">
                                            <p
                                                className={"paragraph center-block"}
                                                aria-hidden="true"
                                            >
                                                Streak
                                            </p>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={"focus-div"} aria-hidden="true">
                                        <div
                                            data-w-id="77e292ba-d8a8-5237-706f-81ed48b7258e"
                                            class="parallax-number-container"
                                            aria-hidden="true"
                                        >
                                            <h3 className={"heading-special"} aria-hidden="true">
                                                {workout.focus}
                                            </h3>
                                            <h3
                                                className={"heading-special-hollow"}
                                                aria-hidden="true"
                                            >
                                                {workout.focus}
                                            </h3>
                                        </div>
                                        <div aria-hidden="true">
                                            <p
                                                className={"paragraph center-block"}
                                                aria-hidden="true"
                                            >
                                                Focus
                                            </p>
                                            {workout.status === CONSTANT.STATUS.ACTIVE && this.userCanUpdate(workout.userScoreUpdate) && (
                                                <div class="div-block-15" aria-hidden="true">
                                                    <ButtonGroup
                                                        size="small"
                                                        aria-label="small outlined button group"
                                                    >
                                                        <Button
                                                            className={"button-3 w-button"}
                                                            onClick={this.handleFocusDecrement}
                                                        >
                                                            -
                                                        </Button>
                                                        <Button className={"button-3 w-button"} disabled>
                                                            {counter.focus}
                                                        </Button>
                                                        <Button
                                                            className={"button-3 w-button"}
                                                            onClick={this.handleFocusIncrement}
                                                        >
                                                            +
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={"time-div"} aria-hidden="true">
                                        <div
                                            className={"parallax-number-container"}
                                            aria-hidden="true"
                                        >
                                            <h3 className={"heading-special"} aria-hidden="true">
                                                {workout.time}
                                            </h3>
                                            <h3
                                                className={"heading-special-hollow"}
                                                aria-hidden="true"
                                            >
                                                {workout.time}
                                            </h3>
                                        </div>
                                        <div aria-hidden="true">
                                            <p
                                                className={"paragraph center-block"}
                                                aria-hidden="true"
                                            >
                                                Time
                                            </p>
                                            {workout.status === CONSTANT.STATUS.ACTIVE && this.userCanUpdate(workout.userScoreUpdate) && (
                                                <div className={"div-block-15"} aria-hidden="true">
                                                    <ButtonGroup
                                                        className={"button-group"}
                                                        size="small"
                                                        aria-label="small outlined button group"
                                                    >
                                                        <Button
                                                            className={"button-3 w-button"}
                                                            onClick={this.handleTimeDecrement}
                                                        >
                                                            -
                                                        </Button>
                                                        <Button className={"button-3 w-button"} disabled>
                                                            {counter.time}
                                                        </Button>
                                                        <Button
                                                            className={"button-3 w-button"}
                                                            onClick={this.handleTimeIncrement}
                                                        >
                                                            +
                                                        </Button>
                                                    </ButtonGroup>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <br/>
                                <br/>
                                {(workout.status === CONSTANT.STATUS.ACTIVE && this.userCanUpdate(workout.userScoreUpdate) && (
                                        <Box display="flex" justifyContent="center">
                                            <Button
                                                className={"button-submit w-button"}
                                                onClick={this.handleSubmit}
                                                disabled={counter.time ? false : true}
                                            >
                                                Submit
                                            </Button>
                                        </Box>
                                    )) ||
                                    (workout.status === CONSTANT.STATUS.ACTIVE && !this.userCanUpdate(workout.userScoreUpdate) && (
                                        <Box display="flex" justifyContent="center" className={"come-back"}>
                                            Come back again tomorrow to certify
                                        </Box>
                                    )) ||
                                    (workout.status === CONSTANT.STATUS.INACTIVE && (
                                        <Box display="flex" justifyContent="center">
                                            <Button
                                                className={"button-submit w-button"}
                                                onClick={() =>
                                                    this.handleSubscription()
                                                }
                                            >
                                                Subscribe
                                            </Button>
                                        </Box>
                                    )) ||
                                    (workout.status === CONSTANT.STATUS.READY && (
                                        <Box display="flex" justifyContent="center">
                                            <Button
                                                className={"button-submit w-button"}
                                                onClick={() =>
                                                    startProgram(workout.id)
                                                }
                                            >
                                                Start
                                            </Button>
                                        </Box>
                                    ))}
                            </Grid>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Workout.propTypes = {
    classes: PropTypes.object.isRequired,
    workout: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {
        doGetWorkoutByUserMail: (params, callback) =>
            dispatch(getWorkoutByUserMail(params, callback)),
        doCreateWorkout: (params, callback) =>
            dispatch(createWorkout(params, callback)),
        doUpdateWorkout: (params, callback) =>
            dispatch(updateWorkout(params, callback)),
        doGetUserByMail: (params, callback) =>
            dispatch(getUserByMail(params, callback)),
        doCreateCheckoutSession: (params, callback) =>
            dispatch(createCheckoutSession(params, callback)),
        doCreatePortalSession: (params, callback) =>
            dispatch(createPortalSession(params, callback)),
    };
};

const connectedWorkout = withRouter(
    connect(mapStateToProps, mapDispatchToProps, null, {
        pure: false,
    })(withStyles(styles)(Workout))
);

export {connectedWorkout as Workout};
