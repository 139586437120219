import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class UserUpdateApi extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {};
  url = BE_NEURO_URL+"user/update/";

  constructor(user, userId) {
    super();
    this.body=user;
    this.url = this.url + userId;
    console.log("UserUpdateApi-",this.url,user);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
