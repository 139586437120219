export default function errorLookup(error) {
  const errorObject = {
    isError: true,
    code: error ? error.errCode : "",
    message: "Application Error",
  };
  if (error && error.errCode) {
    switch (error.errCode) {
      // ******
      default:
        break;
    }
  }
  return errorObject;
}
