import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";

export default class UnsubscribeEmailApi extends BaseApiRequest {
  method = METHOD.HTTP.GET;

  body = {};
  url = BE_NEURO_URL + "registration/user/unsubscribe/";

  constructor(email) {
    super();
    this.url = this.url + email;
    this.headers = {
      "content-type": "application/json",
    };

    console.log("UnsubscribeEmailApi-", this.url);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
