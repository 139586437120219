import STRINGS from "./Strings";

export const config = {
    getBaseUri,
    getVersion
};

export function getBaseUri(){
    const env = process.env.REACT_APP_ENV;
    let baseUri = "";
    if (env === 'dev' || env === 'prod' || env === 'uat' || env === 'staging') {
        baseUri = process.env.REACT_APP_PUBLIC_URL;
    } else {
        baseUri = "/";
    }

    return "";
}

export function getVersion(){
    return process.env.REACT_APP_VERSION;
}
