import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import WorkoutCategoryCreateApi from "../../../communication/api/WorkoutCategoryCreateApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("WorkoutCategoryCreateApi",action);
  const workout = action.data.workout;

  const workoutCategoryCreateApi = new WorkoutCategoryCreateApi(workout);
  try {

    const result = yield call(workoutCategoryCreateApi.getData);
    console.log("WorkoutCategoryCreateApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("WorkoutCategoryCreateApi",error);
   // yield put(showApiError(error));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.WORKOUT_CREATE, handleSaga);
}

export default sagaWatcher;
