import * as actionTypes from '../../../redux/actions/actionTypes';

export const createCheckoutSession = (params,callback = () =>{}) =>({
    type: actionTypes.CREATE_CHECKOUT_SESSION,
    data:{
        priceLookup: params.priceLookup
    },
    callback
});

export const createPortalSession = (params,callback = () =>{}) =>({
    type: actionTypes.CREATE_PORTAL_SESSION,
    data:{
        priceLookup: params.priceLookup
    },
    callback
});

export const getSubscriptions = (callback = () =>{}) =>({
    type: actionTypes.GET_SUBSCRIPTIONS,
    callback
});

export const getMemberships = (params,callback = () =>{}) =>({
    type: actionTypes.GET_MEMBERSHIPS,
    data:{
        username: params.username
    },
    callback
});

export const sendEmail = (params,callback = () =>{}) =>({
    type: actionTypes.SEND_EMAIL,
    data:{
        email: params.email
    },
    callback
});