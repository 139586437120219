import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Material
import { Grid } from "@material-ui/core";

//Component
import { HowItWorks } from "./how.it.works";
import { ClinicallyProven } from "./clinically.proven";
import { Programs } from "./programs";
import { Testimonial } from "./testimonial";
import Marquee from "react-fast-marquee";

//Constants
import STRINGS from "../../constants/Strings";

//Action
import { getLeaders } from "../dashboard/actions/WorkoutAction";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

// Css
import "./styles/home.page.css";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Test",
      leaders: [],
    };
  }
  componentDidMount() {
    this.getLeaders();
  }

  getLeaders() {
    const { doGetLeaders } = this.props;
    const params = {
      top: 10,
    };

    doGetLeaders(params, (res) => {
      console.log("getLeaders", res);
      if (res && res.length !== 0) {
        const leaders = res;

        this.setState({
          leaders: leaders,
        });
      }
    });
  }

  handleChangebtn = (event) => {
    const value = event.currentTarget.value;
    this.setState({ title: "test2" });
  };

  render() {
    const { classes } = this.props;
    const { leaders } = this.state;

    return (
      <>
        <Grid container spacing={0}>
          <div className={"banner bottom-buffer wf-section"}>
            <div
              className={"div-block-homepage w-inline-block"}
              style={{
                transform:
                  "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                transformStyle: "preserve-3d",
              }}
            >
              {/* <Marquee play={true} direction={"left"} loop={0} speed={40}>
                            {leaders.map((raw, idx) => {
                                return (
                                    <h1 className={"heading-2 ticker center"}>
                                        {raw.username + ' ' + raw.score + ' NPS'}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </h1>
                                )
                            })}
                            {leaders.map((raw, idx) => {
                                return (
                                    <h1 className={"heading-2 ticker center"}>
                                        {raw.username + ' ' + raw.score + ' NPS'}
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    </h1>
                                )
                            })}
                        </Marquee> */}
            </div>
          </div>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            {/** */}
            <div className={"hero wf-section"}>
              <div className={"width-div-banner absolute"}>
                {/* <div className={"sticky"}>
                  <div className={"overflow"}>
                    <div className={"section-row"}> */}
                <Marquee
                  play={true}
                  direction={"left"}
                  speed={50}
                  gradientWidth={0}
                >
                  <h1 className={"leader-head"}>
                    {STRINGS.APP_TITLE}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {`\\\\`}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {leaders.map((raw, idx) => {
                      if(raw.rank === 1){
                        return (
                            <>
                              {`${raw.rank}. @${raw.username} ` +
                                  `${raw.score} ` +
                                  `${raw.code} ` +
                                  `+${raw.streak} ` +
                                  `NPS ${raw.overall}`}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        );
                      }else{
                        return (
                            <>
                              {`${raw.rank}. @${raw.username} ` +
                                  `${raw.score} ` +
                                  `${raw.code} ` +
                                  `+${raw.streak} ` +
                                  `NPS ${raw.overall}`}
                              &nbsp;&nbsp;&nbsp;&nbsp;
                            </>
                        );
                      }
                    })}
                  </h1>
                </Marquee>
                {/* </div>
                  </div>
                </div> */}
              </div>
              <div className={"main-image w-embed"}>
                <video
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  playsinline
                  autoPlay
                  loop
                  muted
                >
                  <source
                    src="https://i.imgur.com/IMitK8j.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>
            </div>
            <div className={"brands wf-section"}>
              <div className={"div-block-6"}>
                <p className={"paragraph"}>{STRINGS.HOMEPAGE.BRAND_DETAILS}</p>
              </div>
            </div>

            {/**/}
          </Grid>

          <ClinicallyProven />

          <Box pt={100} />
          <Programs />
          {/***/}
          <HowItWorks></HowItWorks>

          <Testimonial />

          {/* <div className={"section-2  "}>
                    <a className={"div-block-5 w-inline-block"} style={{
                            transform: "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)", 
                            transformStyle: "preserve-3d",
                        }}
                        href="#"
                        >
                        <h1 className={"heading-2 ticker center"}>
                        Use your extended health benefits to arrange a telehealth visit to ensure you are progressing through the program correctly
                        </h1>
                    </a>
                </div> */}
        </Grid>
      </>
    );
  }
}

HomePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    doGetLeaders: (params, callback) => dispatch(getLeaders(params, callback)),
  };
};

const connectedHomePage = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
  })(withStyles(styles)(HomePage))
);

export { connectedHomePage as HomePage };
