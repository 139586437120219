export const METHOD = {
  HTTP: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
};

export const PRODUCTION_ENV = "production";

export const HTTP_STATUS = {
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  BAD_REQUEST: 400,
  UNAUTHORISED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};

export const ERROR_CODE = {
  NOT_FOUND: "100000",

};

export const SUBSCRIPTION_TYPE = {
  MONTHLY: "M",
  YEARLY: "Y",
  GROUP: "G",
  LEVEL: "L",
  REQUIRES: "X",
}

const CONSTANT = {
  ENV: {
    STAGING: "staging",
    UAT: "uat",
    PROD: "production",
  },
  MODAL_TYPE: {
    SUCCESS: "Success",
    FAILURE: "Error",
    INFORMATION: "Info",
    CUSTOM: "Custom",
  },
  DATE_FORMAT: "yyyy-MM-dd",
  DATE_TIME_FORMAT: "yyyy-MM-dd HH:mm:ss",
  STATUS: {
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    READY: "Ready",
    LOCKED: "Locked",
  },
  IMAGES: [
    "",
    "/resources/ground-control.svg",
    "/resources/shoulder-codex.png",
    "/resources/centrepoint.svg",
    "/resources/ground-control-2.png",
    "/resources/shoulder-codex-2.png",
    "/resources/aera.png",
    "/resources/infinity.png",
  ],
  REGISTRATION: {
    NUMBERFORMAT: "(###) ###-####",
    NUMBERPREFIX: "1",
    EMAILPATTERN: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    USERNAMEPATTERN: /^(?!.*[!@#\$%\^&\*])(?=.{5,})/,
    MOBILENOPATTERN: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/,
    CONTACT_NO_PATTERN: /[- )(]/g,
    PASSWORDPATTERN: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    // PASSWORDPATTERN: /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/,
  },

};

export default CONSTANT;