import React, {Component} from 'react'

import {withStyles} from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

// styles
import './policy.css';
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";

const styles = (theme) => ({
    root: {},
    containerTop: {
        marginTop: 50,
    },

});


class PolicyPage extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {

    }


    render() {

        return (
            <Grid container spacing={50}>
                <Grid item xs={12} className={"policy"}>
                    <Typography className={"policy-header"}>Neuroplasticity Gym Privacy Policy</Typography>
                    <span className={"policy-text"}>
                    Thank you for visiting the Neuroplasticity Gym website (the “Site”). This Privacy Policy explains the manner in which we may collect, use and disclose personal information which you provide to us. By visiting this Site or otherwise providing us with your personal information, you agree to be bound by the terms of this policy.
                    </span>
                    <br/>
                    <span className={"policy-text"}>
                    Neuroplasticity Gym is committed to protecting your privacy.
                    </span>
                    <br/>
                    <span className={"policy-text"}>
                    EFFECTIVE DATE: Feb 10, 2023
                    </span>
                    <br/>
                    <span className={"policy-text"}>
                    COLLECTION AND USE OF PERSONAL INFORMATION</span>
                    <span className={"policy-text"}>
                    We only collect personal information that is provided on your behalf. Personal information is collected by registering a profile and buying a subscription through Stripe or emailing us through our contract form. Such information may include your name, phone number, email address, mailing address, credit card or banking information or other information.
                    </span>
                    <br/>
                    <span className={"policy-text"}>
                    By providing your personal information to us, you are authorizing us to collect, use and disclose such information in the manner outlined in this policy.
                    </span>
                    <br/>
                    <span className={"policy-text"}>
                    We may periodically send you emails or similar communications featuring sales and news about new Neuroplasticity Gym products and services, or your feedback in accordance with local law. You may unsubscribe from our services at any time.
                    </span>
                    <br/>
                    <br/>
                    <span className={"policy-text"}>
                    DISCLOSURE OF PERSONAL INFORMATION</span>
                    <span className={"policy-text"}>
                    Neuroplasticity Gym will not disclose, trade, rent, sell or otherwise transfer your personal information for purposes other than as those set out herein, except with your consent or as required or permitted by law.
                    </span>
                    <span className={"policy-text"}>Neuroplasticity Gym may disclose your personal information to third party service providers or agents who provide services to or on behalf of Neuroplasticity Gym such as, but not limited to, hosting, data management and storage services, located in Canada.</span>
                    <br/>
                    <span className={"policy-text"}>
                    Your personal information may be shared in connection with a merger, sale, corporate reorganization or similar business transaction involving Neuroplasticity Gym.
                    </span>
                    <br/>
                    <br/>
                    <span className={"policy-text"}>
                    ACCESS TO YOUR PERSONAL INFORMATION
                    </span>
                    <span className={"policy-text"}>
                    You have a right to challenge the accuracy and completeness of your personal information. To request access or to amend your personal information, please contact Neuroplasticity Gym in writing at MVMTLAB Inc, 500-55.Water St, Vancouver BC, V6B1A1.
                    </span>

                    <br/>
                    <span className={"policy-text"}>
                    SECURITY
                    </span>
                    <span className={"policy-text"}>
                    We strive to protect your personal information, Neuroplasticity Gym cannot ensure or warrant the security of any information you transmit to us and you do so at your own risk.
                    </span>

                    <br/>
                    <span className={"policy-text"}>
                    USE OF THIS SITE BY MINORS
                    </span>
                    <span className={"policy-text"}>
                    This is a commercial website intended for use by individuals that are at least 18 years old. If you are less than 18 years old, you may not use this Site without the prior permission and supervision of your legal guardian. Protecting the privacy of the very young is especially important. For that reason, we never collect or maintain information at this Site from those we actually know are under the age of 13, and no part of this Site is structured to attract anyone under age 13 to provide information to us.
                    </span>

                    <br/>
                    <span className={"policy-text"}>
                    TERMS OF USE
                    </span>
                    <span className={"policy-text"}>
                    Your use of this Site and any issues relating to this policy are subject to Neuroplasticity Gym then-current Terms of Use, which are located on our Site.
                    </span>

                    <br/>
                    <span className={"policy-text"}>
                    PRIVACY POLICY UPDATES
                    </span>
                    <span className={"policy-text"}>
                    We reserve the right to update or modify this policy at any time and from time to time without prior notice by posting a revised version of the policy on the Site. If we make material changes to this policy, we will notify you by means of a notice on our homepage. Your use of the Site or any Neuroplasticity Gym/MVMTLAB facility following any revision to the policy constitutes your agreement that all personal information collected from or about you after the revised policy is posted will be subject to the terms and conditions of the revised policy. The date listed above indicates the most recent change or update to this policy.
                    </span>

                    <br/>
                    <span className={"policy-text"}>QUESTIONS OR COMMENTS</span>
                    <span className={"policy-text"}>If you have questions or comments about Neuroplasticity Gym's Online Privacy Policy, please email us at hello@mvmtlab.com, or write to us at:</span>
                    <br/>
                    <span className={"policy-text"}>MVMTLAB Inc.</span>
                    <span className={"policy-text"}>#500-55 Water St</span>
                    <span className={"policy-text"}>Vancouver BC, Canada V6B1A1</span>
                </Grid>
            </Grid>
        );
    }
}

PolicyPage.propTypes = {};

function mapStateToProps(state) {
    return state;
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

const connectedPolicyPage = withRouter(connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false
})(withStyles(styles)(PolicyPage)));

export {connectedPolicyPage as PolicyPage};
