const errorMessages = {
    required: (errObj, fieldName, fieldType) => {
      if (fieldType === "select") {
        return `Please select ${fieldName}`;
      }
      if (fieldType === "checkbox") {
        return `Please ${fieldName}`;
      }
      return `Please enter ${fieldName}`;
    },
    pattern: (errObj, fieldName, fieldType) => {
        if(fieldType === 'password') return 'The password must contain atleast 1 lowercase, uppercase, numeric, special character and must be 8 characters or longer';
        if(fieldType === 'username') return 'The username must be 5 characters or longer without special characters';

        return `Enter valid ${fieldName}`
    },
    minLength: (param) => `Min ${param} numbers required `,
    maxLength: (param) => `Max chars allowed is ${param}`,
    equalLength: (param) => `Please enter ${param} digit number`,
    amount: (errObj, fieldName) => {
      return `Please enter ${fieldName}`;
    },
  };
  
  const getErrorMsg = (err, errObj, fieldName, fieldType) => {
    return errorMessages[err](errObj, fieldName, fieldType);
  };
  
  export default getErrorMsg;
  