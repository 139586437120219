import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import {
    showApiError
} from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

  
import UserUpdateApi from "../../../communication/api/UserUpdateApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("UserUpdateApi",action);
  
  const user = {
      username: action.data.user.username,
      mail: action.data.user.mail,
      password: action.data.user.password
  }

  const userUpdateApi = new UserUpdateApi(user,action.data.user.id);
  try {

    const result = yield call(userUpdateApi.getData);
    console.log("UserUpdateApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("UserUpdateApi",error);
    yield put(showApiError(error.toString()));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.UPDATE_USER, handleSaga);
}

export default sagaWatcher;
