export const APPLICATION_ERROR = "APPLICATION_ERROR";
export const LOADING_START = "LOADING_START";
export const LOADING_FINISH = "LOADING_FINISH";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const WORKOUT_BY_USER_ID = "WORKOUT_BY_USER_ID";
export const WORKOUT_BY_USER_ID_SUCCESS = "WORKOUT_BY_USER_ID_SUCCESS";
export const WORKOUT_BY_USER_ID_ERROR = "WORKOUT_BY_USER_ID_ERROR";

export const WORKOUT_CREATE = "WORKOUT_CREATE";
export const WORKOUT_CREATE_SUCCESS = "WORKOUT_CREATE_SUCCESS";
export const WORKOUT_CREATE_ERROR = "WORKOUT_CREATE_ERROR";

export const WORKOUT_START = "WORKOUT_START";
export const WORKOUT_START_SUCCESS = "WORKOUT_START_SUCCESS";
export const WORKOUT_START_ERROR = "WORKOUT_START_ERROR";

export const WORKOUT_UPDATE = "WORKOUT_UPDATE";
export const WORKOUT_UPDATE_SUCCESS = "WORKOUT_UPDATE_SUCCESS";
export const WORKOUT_UPDATE_ERROR = "WORKOUT_UPDATE_ERROR";

export const WORKOUT_BY_USER_MAIL = "WORKOUT_BY_USER_MAIL";
export const WORKOUT_BY_USER_MAIL_SUCCESS = "WORKOUT_BY_USER_MAIL_SUCCESS";
export const WORKOUT_BY_USER_MAIL_ERROR = "WORKOUT_BY_USER_MAIL_ERROR";

export const GET_USER_BY_MAIL = "GET_USER_BY_MAIL";
export const GET_USER_BY_MAIL_SUCCESS = "GET_USER_BY_MAIL_SUCCESS";
export const GET_USER_BY_MAIL_ERROR = "GET_USER_BY_MAIL_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USERL_ERROR = "UPDATE_USERL_ERROR";

export const CREATE_CHECKOUT_SESSION = "CREATE_CHECKOUT_SESSION";
export const CREATE_CHECKOUT_SESSION_SUCCESS =
  "CREATE_CHECKOUT_SESSION_SUCCESS";
export const CREATE_CHECKOUT_SESSION_ERROR = "CREATE_CHECKOUT_SESSION_ERROR";

export const CREATE_PORTAL_SESSION = "CREATE_PORTAL_SESSION";
export const CREATE_PORTAL_SESSION_SUCCESS = "CREATE_PORTAL_SESSION_SUCCESS";
export const CREATE_PORTAL_SESSION_ERROR = "CREATE_PORTAL_SESSION_ERROR";

export const GET_LEADERS = "GET_LEADERS";
export const GET_LEADERS_SUCCESS = "GET_LEADERS_SUCCESS";
export const GET_LEADERS_ERROR = "GET_LEADERS_ERROR";

export const SET_USER = "SET_USER";
export const USER_SUCCESS = "USER_SUCCESS";
export const USER_ERROR = "USER_ERROR";

export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS";
export const GET_SUBSCRIPTIONS_SUCCESS = "GET_SUBSCRIPTIONS_SUCCESS";
export const GET_SUBSCRIPTIONS_ERROR = "GET_SUBSCRIPTIONS_ERROR";

export const GET_MEMBERSHIPS = "GET_MEMBERSHIPS";
export const GET_MEMBERSHIPS_SUCCESS = "GET_MEMBERSHIPS_SUCCESS";
export const GET_MEMBERSHIPS_ERROR = "GET_MEMBERSHIPS_ERROR";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";

export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_ERROR = "CHANGE_USER_PASSWORD_ERROR";

export const PAYMENT_COMPLETE = "PAYMENT_COMPLETE";

export const UNSUBSCRIBE_EMAIL = "UNSUBSCRIBE_EMAIL";

export const CONTACT_EMAIL = "CONTACT_EMAIL";