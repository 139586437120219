import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import RegisterUserApi from "../../../communication/api/RegisterUserApi";

import Moment from 'moment';
import MomentTZ from 'moment-timezone';

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("RegisterUserApi",action);
  const user = action.data.user;

  const registerUserApi = new RegisterUserApi(user);
  try {

    const result = yield call(registerUserApi.getData);
    console.log("RegisterUserApi",result);
     yield call(action.callback, result.data);

  } catch (error) {
    console.log("RegisterUserApi",error);
   // yield put(showApiError(error));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.REGISTER_USER, handleSaga);
}

export default sagaWatcher;
