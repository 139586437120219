import BaseApiRequest from "./BaseApiRequest";
import { BE_NEURO_URL } from "../ApiUrls";
import { METHOD } from "../Constants";
import axios from 'axios';

export default class LoginUserApi extends BaseApiRequest {
  method = METHOD.HTTP.POST;

  body = {};
  url = BE_NEURO_URL+"oauth/token";

  constructor(user) {
    super();


    this.headers = {
        'content-type': 'multipart/form-data',
        'Authorization': 'Basic bmV1cm86Y2hhbmdlaXQ='
    }

    this.body = user;
    console.log("LoginUserApi",this.body,this.url);
  }

  getMethod = () => {
    return this.method;
  };

  getUrl = () => {
    return this.url;
  };

  getBody = () => {
    return this.body;
  };
}
