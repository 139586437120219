import { call, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../../../redux/actions/actionTypes";

import {
  doLoadingFinish,
  doLoadingStart,
} from "../../common/ProgressIndicator/Actions/ProgressIndicatorActions";

import { showApiError } from "../../common/ErrorApiIndicator/Actions/ErrorApiIndicatorActions";

import LoginUserApi from "../../../communication/api/LoginUserApi";

import Moment from "moment";
import MomentTZ from "moment-timezone";
import axios, { post } from "axios";
import UnsubscribeEmailApi from "../../../communication/api/UnsubscribeEmailApi";

// worker
function* handleSaga(action) {
  yield put(doLoadingStart(action.type));

  console.log("UnsubscribeEmailApi", action);
  const mail = action.data.mail;

  const unsubscribeEmailApi = new UnsubscribeEmailApi(mail);
  try {
    const result = yield call(unsubscribeEmailApi.getData);

    console.log("UnsubscribeEmailApi", result);

    yield call(action.callback, result.data);
  } catch (error) {
    console.log("UnsubscribeEmailApi", error);
    yield put(showApiError("Email not found"));
  } finally {
    yield put(doLoadingFinish(action.type));
  }
}

// watcher
function* sagaWatcher() {
  yield takeLatest(actionTypes.UNSUBSCRIBE_EMAIL, handleSaga);
}

export default sagaWatcher;
