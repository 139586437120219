import React, { useState, Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Material
import Button from "@material-ui/core/Button";

//Components
import { RegisterModal } from "../../registration/register.modal";

//Constants
import STRINGS from "../../../constants/Strings";
import { ForgotPasswordModal } from "../../forgot-password/forgot-password.modal";
import {SUBSCRIPTION_TYPE} from "../../../constants/AppConstants";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

class RegisterSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegistration: false,
      showForgotPasword: false,
      user: {},
      subscriptionType: "",
    };
  }

  handleChangebtn = (event) => {
    const value = event.currentTarget.value;
    this.setState({ title: "test2" });
  };

  viewRegistration = (event, subscriptionType) => {
    this.setState({
      showRegistration: true,
      subscriptionType,
    });
  };

  viewForgotPassowrd = (event) => {
    this.setState({
      showForgotPasword: true,
    });
  };

  handleCloseForgotPasswordModal = (event) => {
    this.setState({
      showForgotPasword: false,
    });
  };

  handleCloseModal = (event) => {
    this.setState({
      showRegistration: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { showRegistration, showForgotPasword, user, subscriptionType } = this.state;

    return (
      <section>
        {/***/}
        <div className={"section-5 wf-section"} id="Buy-sec">
          <div className={"div-block-12"}>
            <div className={"register-section"}>
              <div className={"div-block-6"}>
                <p className={"heading-2"}>
                  {STRINGS.REGISTRATION.MONTHLY_DETAILS}
                </p>
                <Button
                  onClick={(event) => this.viewRegistration(event,SUBSCRIPTION_TYPE.MONTHLY)}
                  variant="contained"
                  color="primary"
                >
                  Sign up
                </Button>
              </div>
            </div>
            <div className={"register-section"}>
              <div className={"div-block-6"}>
                <p className={"heading-2"}>
                  {STRINGS.REGISTRATION.YEARLY_DETAILS}
                </p>
                <Button
                  onClick={(event) => this.viewRegistration(event,SUBSCRIPTION_TYPE.YEARLY)}
                  variant="contained"
                  color="primary"
                >
                  Sign up
                </Button>
              </div>
            </div>
          </div>
        </div>
        <RegisterModal
          showRegistration={showRegistration}
          user={user}
          handleCloseModal={this.handleCloseModal}
          subscriptionType={subscriptionType}
        ></RegisterModal>
        <ForgotPasswordModal
          showModal={showForgotPasword}
          handleCloseModal={this.handleCloseForgotPasswordModal}
        />
      </section>
    );
  }
}

RegisterSection.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps, null, null, {
  pure: false,
})(withStyles(styles)(RegisterSection));

// export default { connectedRegisterSection as RegisterSection };
