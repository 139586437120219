import React, { useState, Component } from "react";
import {
  createSlice,
  createAsyncThunk,
  createSelector,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

//Material
import { Grid } from "@material-ui/core";
import Container from "@material-ui/core/Container";

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import TextField from "@material-ui/core/TextField";

// Components
import { MembershipTab } from "../profile/membership.tab";
import { RegisterModal } from "../registration/register.modal";

import { Info } from "@material-ui/icons";

//Action
import { getUserByMail, updateUser } from "../registration/actions/UserAction";

//Constants
import STRINGS from "../../constants/Strings";
import { config } from "../../constants/Config";

// styles
import "./styles/course.css";
import RegisterSection from "../common/RegisterSection/RegisterSection";

const styles = (theme) => ({
  root: {},
  containerTop: {
    marginTop: 50,
  },
});

const bgImgList = ["", "gc", "bd"];

class CoursePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: {},
      user: {},
      showRegistration: false,
      programList: STRINGS.COURSE_PAGE.PROGRAM_LIST,
    };
  }

  componentDidMount() {
    this.getUser();
  }

  getUser() {
    const { dogetUserByMail } = this.props;
    const mail = sessionStorage.getItem(STRINGS.APP_USERNAME);

    if (mail === null) return;

    dogetUserByMail({ mail: mail }, (user) => {
      console.log("getUser", user);
      if (user) {
        this.setState({ user: user });
      }
    });
  }

  handleChange = (prop) => (event) => {
    const { contact } = this.state;
    if (event.target) {
      contact[prop] = event.target.value;
    } else {
      contact[prop] = event;
    }
    this.setState({ contact: contact });
  };

  viewRegistration = (event) => {
    this.setState({
      showRegistration: true,
    });
  };

  handleCloseModal = (event) => {
    this.setState({
      showRegistration: false,
    });
  };

  render() {
    const accessToken = sessionStorage.getItem(STRINGS.APP_TOKEN);
    const { classes } = this.props;
    const { showRegistration, user, programList } = this.state;

    const progamListComponent = programList.map((program, idx) => (
      <>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className={"section-course " + bgImgList[idx]}
          key={idx}
        >
          <Grid item xs={12} sm={4} md={4} lg={4} className={"course-img "}>
            <img
              src={program.IMG}
              loading="lazy"
              alt=""
              className={"image-10"}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={8} className={"course-details"}>
            <h2 className={"heading-2-course"}>{program.HEADER}</h2>
            <p className={"paragraph course-paragraph"}>
              <div dangerouslySetInnerHTML={{ __html: program.PARAGRAPH }} />
            </p>
          </Grid>
        </Grid>
      </>
    ));
    return (
      <>
        {/***/}
        <h1 className={"heading rotate"}>Programs</h1>
        <div className={"section-3 auto wf-section"}>
          <div className={"div-block-10"}>
            <h2 className={"heading-2-course"}>{STRINGS.COURSE_PAGE.TITLE}</h2>
            <p className={"paragraph"}>{STRINGS.COURSE_PAGE.PARAGRAPH}</p>
          </div>
        </div>
        <Grid container className={"section-4 "}>
          {progamListComponent}
        </Grid>

        {!accessToken && <RegisterSection />}
      </>
    );
  }
}

CoursePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}

const mapDispatchToProps = (dispatch) => {
  return {
    dogetUserByMail: (params, callback) =>
      dispatch(getUserByMail(params, callback)),
    doupdateUser: (params, callback) => dispatch(updateUser(params, callback)),
  };
};
const connectedCoursePage = withRouter(
  connect(mapStateToProps, mapDispatchToProps, null, {
    pure: false,
  })(withStyles(styles)(CoursePage))
);

export { connectedCoursePage as CoursePage };
